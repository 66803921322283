import loadScript from 'load-script';

import { MapInterface, MapMarkerOptions } from '../typings';

declare global {
  interface Window {
    Tmapv2: any;
  }
}

enum MapEvent {
  MOUSE_DOWN = 'mousedown',
  MOUSE_UP = 'mouseup',
  CLICK = 'click',
  DOUBLE_CLICK = 'dblclick',
  RIGHT_CLICK = 'rightclick',
  MOUSE_OVER = 'mouseover',
  MOUSE_OUT = 'mouseout',
  MOUSE_MOVE = 'mousemove',
  DRAG_START = 'dragstart',
  DRAG = 'drag',
  DRAG_END = 'dragend',
  TOUCH_START = 'touchstart',
  TOUCH_MOVE = 'touchmove',
  TOUCH_END = 'touchend',
  PINCH_START = 'pinchstart',
  PINCH = 'pinch',
  PINCH_END = 'pinchend',
  TAP = 'tap',
  LONG_TAP = 'longtap',
  TWO_FINGER_TAP = 'twofingertap',
  DOUBLE_TAP = 'doubletap',
}

function isTouchEventSupported() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

export default class TMap implements MapInterface {
  private _afterActions: any[] = [];

  private readonly markers: any = {};
  private mapEventListeners: any = {};
  private markerEventListeners: any = {};

  public isDragging: boolean = false;

  public initOptions: any;
  public loaded: boolean = false;
  public initiated: boolean = false;
  public map: any = null;

  private get appKey(): string {
    const { appKey } = this.initOptions ?? {};
    return appKey;
  }

  private get mapModule(): any {
    return window.Tmapv2;
  }

  constructor(options: any) {
    this.initOptions = options;
  }

  load() {
    return new Promise<any>((resolve, reject) => {
      const random = Math.floor(Math.random() * (3 - 1 + 1) + 1);

      let domian = 'topopentile1';
      if (random == 1) {
        domian = 'topopentile1';
      } else if (random == 2) {
        domian = 'topopentile2';
      } else {
        domian = 'topopentile3';
      }

      window.Tmapv2 = {
        _getScriptLocation() {
          return 'https://' + domian + '.tmap.co.kr/scriptSDKV2/';
        },
        VERSION_NUMBER: Math.random(),
      };

      // 버전 계속 수정될 필요 있음...
      const scriptUrl = `https://${domian}.tmap.co.kr/scriptSDKV2/tmapjs2.min.js?version=20230308`;

      loadScript(scriptUrl, (error, script) => {
        if (error) {
          reject(error);
          return;
        }

        this.loaded = true;
        resolve(script);
      });
    });
  }

  _mappingOptions(options) {
    const { minZoom, maxZoom, zoom, logoControl, mapDataControl, scaleControl } = options;

    return {
      zoom,
    };
  }

  async loadView(el: HTMLElement, options: any = {}) {
    if (!this.loaded) {
      await this.load();
    }

    this.loaded = true;

    this.map = new this.mapModule.Map(el, this._mappingOptions(options));
    this.initiated = true;

    this.addMapEventListener(MapEvent.DRAG_START, () => {
      this.isDragging = true;
    });
    this.addMapEventListener(MapEvent.DRAG_END, () => {
      this.isDragging = false;
    });

    return this.map;
  }

  addMapEventListener(eventName: string, callback: Function, options?: any) {
    const mappingEventNames = {
      'zoom-changed': 'zoom_changed',
      'center-changed': 'dragend',
    };

    if (isTouchEventSupported()) {
      mappingEventNames['click'] = 'touchend';
    }

    const listener = this.map.addListener(mappingEventNames[eventName] ?? eventName, callback, options);

    this.mapEventListeners[eventName] = listener;
  }

  removeMapEventListener(eventName: string) {
    const listener = this.mapEventListeners[eventName];

    if (listener) {
      this.mapModule.Event.removeListener(listener);
      delete this.mapEventListeners[listener];
    }
  }

  triggerEvent(target, eventName, eventObject) {
    this.mapModule.Event.trigger(target, eventName, eventObject);
  }

  addMarkerEventListener(marker: any, eventName: string, callback: Function) {
    const { id } = marker;

    const mappingEventNames = {};

    if (isTouchEventSupported()) {
      mappingEventNames['click'] = 'touchend';
    }

    const listener = marker.addListener(mappingEventNames[eventName] ?? eventName, (e) => {
      callback(e);
    });

    if (!this.markerEventListeners[id]) {
      this.markerEventListeners[id] = {};
    }

    this.markerEventListeners[id][eventName] = listener;
  }

  removeMarkerEventListener(marker: any, eventName: string) {
    const { id } = marker;
    const listener = this.markerEventListeners[id]?.[eventName];

    if (listener) {
      this.mapModule.Event.removeListener(listener);
      delete this.markerEventListeners[id][eventName];
    }
  }

  createMarker(options: MapMarkerOptions, callback) {
    // console.log('createMarker', options);

    let icon;

    const { id, latitude, longitude, iconUrl, iconContent, iconSize, zIndex } = options;
    const position = new this.mapModule.LatLng(latitude, longitude);

    if (iconUrl) {
      icon = iconUrl;
    }
    // else if (iconContent) {
    //   icon = {
    //     // url: iconUrl || '',
    //     content: iconContent || '',
    //     size: new this.mapModule.Size(iconSize.width, iconSize.height),
    //     scaledSize: new this.mapModule.Size(iconSize.width, iconSize.height),
    //     origin: new this.mapModule.Point(0, 0),
    //     anchor: new this.mapModule.Point(0, 0),
    //   };
    // }

    const marker = new this.mapModule.Marker({
      // id,
      icon,
      position,
      // zIndex,
      // draggable : true,
      map: this.map,
    });

    this.markers[id] = marker;

    if (callback) {
      callback(marker);
    }
  }

  updateMarker(marker: any, options: MapMarkerOptions) {
    // console.log( "updateMarker", options );

    const { id, latitude, longitude, iconUrl, iconContent, iconSize, zIndex } = options;

    let icon;

    if (iconUrl) {
      marker.setIcon(iconUrl);
    }
    // else if (iconContent) {
    //   icon = {
    //     // url: iconUrl || '',
    //     content: iconContent || '',
    //     size: new this.mapModule.Size(iconSize.width, iconSize.height),
    //     scaledSize: new this.mapModule.Size(iconSize.width, iconSize.height),
    //     origin: new this.mapModule.Point(0, 0),
    //     anchor: new this.mapModule.Point(0, 0),
    //   };

    //   marker.setIcon(icon);
    // }

    if (zIndex) {
      // marker.setZIndex(zIndex);
    }
  }

  removeMarker({ id }) {
    if (this.markers[id]) {
      this.markers[id].setMap(null);
      delete this.markers[id];
    }
  }

  clearMarkers() {
    Object.keys(this.markers).forEach((id) => {
      if (this.markers[id]) {
        this.markers[id].setMap(null);
        delete this.markers[id];
      }
    });
  }

  updateMarkers() {
    const mapBounds = this.map.getBounds();

    const data = {
      hidden: {},
      shown: {},
    };

    Object.keys(this.markers).forEach((key) => {
      const marker = this.markers[key];
      const position = marker.getPosition();

      if (mapBounds.hasLatLng(position)) {
        this.showMarker(marker);
        data.shown[key] = marker;
      } else {
        this.hideMarker(marker);
        data.hidden[key] = marker;
      }
    });

    return data;
  }

  isInBounds(key) {
    const mapBounds = this.map.getBounds();
    const target = this.markers[key];

    if (target) {
      const position = target.getPosition();
      return mapBounds.hasLatLng(position);
    }

    return false;
  }

  private showMarker(marker) {
    if (marker.getMap()) {
      return;
    }

    marker.setMap(this.map);
  }

  private hideMarker(marker) {
    if (!marker.getMap()) {
      return;
    }
    marker.setMap(null);
  }

  getZoom() {
    return this.map.getZoom();
  }

  setZoom(value) {
    const zoom = Math.round(value);
    this.map.setZoom(zoom);
  }

  getCenter() {
    const { x, y } = this.map.getCenter();

    return {
      latitude: y,
      longitude: x,
    };
  }

  setCenter(coords) {
    const { latitude, longitude } = coords;

    this.map.setCenter(new this.mapModule.LatLng(latitude, longitude));
  }

  panTo(coords) {
    const { latitude, longitude } = coords;

    const center = new this.mapModule.LatLng(latitude, longitude);

    this.map.panTo(center);
  }

  getRadius() {
    const bounds = this.map.getBounds();
    const ne = bounds.getNE();
    const center = this.map.getCenter();
    const radius = this.getDistanceBetween(center, ne);

    return radius;
  }

  setSize(width, height) {
    const size = new this.mapModule.Size(width, height);
    this.map.setSize(size);
  }

  resize() {
    this.map.autoResize();
  }

  destroy() {
    this.map.destroy();
  }

  getDistanceBetween(pointA, pointB) {
    const points = [pointA, pointB].map(
      (point) => new this.mapModule.LatLng(point?.latitude || point?.x, point?.longitude || point?.y),
    );

    const mapSystemProjection = this.map.getProjection();
    const distance = mapSystemProjection.getDistance(...points);

    return distance;
  }
}
