import { normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notion"
}
const _hoisted_2 = ["alt", "src"]
const _hoisted_3 = { class: "notion-title" }
const _hoisted_4 = {
  key: 1,
  class: "notion"
}
const _hoisted_5 = { class: "notion-page-icon" }
const _hoisted_6 = { class: "notion-page-text" }
const _hoisted_7 = { class: "notion-page-icon" }
const _hoisted_8 = { class: "notion-page-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotionPageIcon = _resolveComponent("NotionPageIcon")!
  const _component_NotionTextRenderer = _resolveComponent("NotionTextRenderer")!
  const _component_notion_link = _resolveComponent("notion-link")!

  return (_ctx.level === 0 && _ctx.fullPage)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.format && _ctx.format.page_cover)
          ? (_openBlock(), _createElementBlock("img", {
              key: 0,
              class: "notion-page-cover",
              style: _normalizeStyle($options.coverStyle),
              alt: _ctx.getTextContent(_ctx.title),
              src: _ctx.mapImageUrl(_ctx.format.page_cover, _ctx.block)
            }, null, 12, _hoisted_2))
          : _createCommentVNode("", true),
        _createElementVNode("main", {
          class: _normalizeClass([
        'notion-page',
        _ctx.format && !_ctx.format.page_cover && 'notion-page-offset',
        _ctx.format && _ctx.format.page_full_width && 'notion-full-width',
        _ctx.format && _ctx.format.page_small_text && 'notion-small-text',
      ])
        }, [
          _createVNode(_component_NotionPageIcon, _mergeProps(_ctx.pass, { big: "" }), null, 16),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
          ]),
          _renderSlot(_ctx.$slots, "default")
        ], 2)
      ]))
    : (_ctx.level === 0)
      ? (_openBlock(), _createElementBlock("main", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default")
        ]))
      : (_ctx.hasPageLinkOptions)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.pageLinkOptions.component), _mergeProps({ key: 2 }, _ctx.pageLinkProps(_ctx.value.id), { class: "notion-page-link" }), {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_5, [
                _createVNode(_component_NotionPageIcon, _normalizeProps(_guardReactiveProps(_ctx.pass)), null, 16)
              ]),
              _createElementVNode("div", _hoisted_6, [
                _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
              ])
            ]),
            _: 1
          }, 16))
        : (_openBlock(), _createBlock(_component_notion_link, {
            key: 3,
            class: "notion-page-link",
            target: _ctx.pageLinkTarget,
            href: _ctx.mapPageUrl(_ctx.value.id)
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_NotionPageIcon, _normalizeProps(_guardReactiveProps(_ctx.pass)), null, 16)
              ]),
              _createElementVNode("div", _hoisted_8, [
                _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
              ])
            ]),
            _: 1
          }, 8, ["target", "href"]))
}