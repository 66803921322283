import _ from 'lodash';
import { defineStore } from 'pinia';
import { markRaw } from 'vue';

export enum DrawerPosition {
  CENTER = 'CENTER',
  BOTTOM = 'BOTTOM',
}

export enum TransitionName {
  'SLIDE_LEFT' = 'slide-left',
  'SLIDE_RIGHT' = 'slide-right',
  'SLIDE_UP' = 'slide-up',
  'SLIDE_DOWN' = 'slide-down',
  'FADE' = 'fade',
  'NONE' = 'none',
  'DEFAULT' = 'slide-up',
}

export enum DrawerSize {
  AUTO = 'AUTO',
  FULL = 'FULL',
}

export interface DrawerItem {
  id?: string;
  ref?: any;
  component: any;
  data: any;
  events: any;
  canCloseForModal?: boolean;
  useCloseButton?: boolean;
  useModal?: boolean;
  position?: DrawerPosition;
  animationName?: string;
  animationDuration?: number;
  bgColor?: string;
  drawerEvent?: string;
  size?: DrawerSize;
}

export interface DrawerState {
  items: DrawerItem[];
  observers: any;
  transitionName?: TransitionName;
}

export const useDrawerStore = defineStore({
  id: 'drawer',
  state: (): DrawerState => ({
    items: [],
    observers: [],
    transitionName: TransitionName.DEFAULT,
  }),
  getters: {},
  actions: {
    clear() {
      this.items = [];
      this.observers = [];
    },

    addObserver(handler: Function) {
      const observer = {
        id: _.uniqueId(),
        handler,
      };
      this.observers[observer.id] = observer;
      return observer;
    },

    removerObserver(observer: any) {
      if (this.observers[observer.id]) {
        this.observers[observer.id] = null;
        delete this.observers;
      }
    },

    notifyObservers(action: string, options: any) {
      this.observers.forEach((observer: any) => {
        observer.handler(action, options);
      });
    },

    present(payload: DrawerItem) {
      const item = {
        id: payload.id ?? _.uniqueId(),
        useCloseButton: payload.useCloseButton ?? true,
        position: payload.position ?? DrawerPosition.BOTTOM,
        size: payload.size ?? DrawerSize.AUTO,
        bgColor: payload.bgColor ?? 'white',
        //@TODO: AnimationDuration 값을 옵션널 처리 필요
        animationDuration: payload.animationDuration ?? 600,
        animationName: payload.animationName ?? TransitionName.DEFAULT,
        ...payload,
        component: markRaw(payload.component),
        componentName: payload.component.__name,
      };

      this.transitionName = item.animationName;

      this.notifyObservers('will-present', {
        ...item,
        component: item.componentName,
      });

      setTimeout(() => {
        this.items.push(item);

        this.notifyObservers('did-present', {
          ...item,
          component: payload.component.name,
        });
      }, 0);

      return item;
    },

    dismiss(payload: any) {
      const { id } = payload;

      const drawerItem = this.items.find((item) => item.id === id);

      if (drawerItem) {
        const { onClose } = drawerItem?.events;
        if (onClose && typeof onClose === 'function') {
          onClose(drawerItem);
        }
      }

      this.notifyObservers('will-dismiss', {
        ...drawerItem,
        component: drawerItem.componentName,
      });

      this.items = this.items.filter((item) => item.id !== id);

      this.notifyObservers('did-dismiss', {
        ...drawerItem,
        component: drawerItem.componentName,
      });
    },

    dismissAll() {
      const items = this.items;
      items.forEach((item) => {
        this.dismiss(item);
      });
    },
  },
});
