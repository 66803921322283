<template>
  <ul v-if="isTopLevel && type === 'bulleted_list'" class="notion-list notion-list-disc">
    <li><NotionTextRenderer :text="title" v-bind="pass" /></li>
    <NotionNestedList v-if="value.content" v-bind="pass">
      <slot></slot>
    </NotionNestedList>
  </ul>
  <ol v-else-if="isTopLevel && type === 'numbered_list'" class="notion-list notion-list-numbered" :start="start">
    <li><NotionTextRenderer :text="title" v-bind="pass" /></li>
    <NotionNestedList v-if="value.content" v-bind="pass">
      <slot></slot>
    </NotionNestedList>
  </ol>
  <span v-else>
    <li><NotionTextRenderer :text="title" v-bind="pass" /></li>
    <NotionNestedList v-if="value.content" v-bind="pass">
      <slot></slot>
    </NotionNestedList>
  </span>
</template>

<script lang="ts">
import { Blockable, blockComputed } from '../../composable/blockable';
import { getListNumber } from '../../composable/utils';
import NotionNestedList from '../helpers/nested-list.vue';
import NotionTextRenderer from '../helpers/text-renderer.vue';

export default {
  name: 'NotionList',
  components: { NotionNestedList, NotionTextRenderer },
  extends: Blockable,
  computed: {
    ...blockComputed,
    start() {
      return getListNumber(this.value?.id, this.blockMap);
    },
    isTopLevel() {
      return this.type !== this.blockMap[this.value?.parent_id]?.value?.type;
    },
  },
};
</script>
