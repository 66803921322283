import airbridge from 'airbridge-web-sdk-loader';
import { WNInterface, useWNInterface } from '@/plugins/vue-wni';
import { AnalyticsModule } from '..';

export default class Airbridge implements AnalyticsModule {
  private _appName: string;
  private _webSDKToken: string;
  private _mappingEvents: any;
  private _wni: WNInterface;

  constructor({ appName, webSDKToken }) {
    this._appName = appName;
    this._webSDKToken = webSDKToken;
    this._mappingEvents = {
      complete_signin: 'airbridge.user.signin',
      complete_signup: 'airbridge.user.signup',
      view_home: 'airbridge.ecommerce.home.viewed',
      select_map_marker: 'airbridge.ecommerce.productList.viewed',
      view_space_detail_page: 'airbridge.ecommerce.product.viewed',
      complete_search_keyword_results: 'airbridge.ecommerce.searchResults.viewed',
      add_bookmark_space: 'airbridge.addToWishlist',
      complete_book_space: 'airbridge.subscribe',
      complete_book_space_cancel: 'airbridge.unsubscribe',
      complete_create_seek_space: 'complete_create_seek_space',
      // complete_register_space_basic: 'complete_register_space_basic', 19
      complete_register_space: 'complete_register_space',
      select_logout_button: 'airbridge.user.signout',
      // select_suggest_space: 'select_suggest_space',
      complete_suggest_space: 'complete_suggest_space',
      select_register_space_exite_popup_exit_button: 'exit_register_space',
    };
    this._wni = useWNInterface();
  }

  get isSDKEnabled() {
    return airbridge.isSDKEnabled;
  }

  async init() {
    if (this._webSDKToken) {
      await airbridge.init({
        app: this._appName,
        webToken: this._webSDKToken,
      });
    } else {
      console.error('undefined sdk token');
    }
  }

  event(name: any, options?: { common: any; airbridge: any; [key: string]: any }) {
    const { common = {}, airbridge: airbridgeProperties = {} } = options ?? {};
    const eventName = this._mappingEvents[name];

    if (!eventName) {
      return;
    }

    if (this._wni.isNative) {
      this._wni.execute('wnAnalyticsAirbridgeSendEvent', {
        ...airbridgeProperties,
        customAttributes: common,
        category: eventName,
      });
    } else {
      airbridge.events.send(eventName, {
        ...airbridgeProperties,
        customAttributes: common,
      });
    }
  }

  setUserId(id: string) {
    if (this._wni.isNative) {
      this._wni.execute('wnAnalyticsAirbridgeSetUserId', { id });
    } else {
      airbridge.setUserId(id);
    }
  }

  setUserProperty(key: string, value: string) {}

  addUserProperty(key: string, value: number) {}

  clearUser() {
    if (this._wni.isNative) {
      this._wni.execute('wnAnalyticsAirbridgeClearUser', {});
    } else {
      airbridge.clearUser();
    }
  }
}
