import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "notion-callout-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotionPageIcon = _resolveComponent("NotionPageIcon")!
  const _component_NotionTextRenderer = _resolveComponent("NotionTextRenderer")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['notion-callout', _ctx.blockColorClass(), _ctx.blockColorClass('_co')])
  }, [
    _createElementVNode("div", null, [
      _createVNode(_component_NotionPageIcon, _normalizeProps(_guardReactiveProps(_ctx.pass)), null, 16)
    ]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
    ])
  ], 2))
}