<template>
  <ul v-if="type === 'bulleted_list'" class="notion-list notion-list-disc">
    <slot></slot>
  </ul>
  <ol v-else class="notion-list notion-list-numbered">
    <slot></slot>
  </ol>
</template>

<script lang="ts">
import { Blockable, blockComputed } from '../../composable/blockable';
import { getListNumber } from '../../composable/utils';

export default {
  name: 'NotionNestedList',
  extends: Blockable,
  computed: {
    ...blockComputed,
    start() {
      return getListNumber(this.value?.id, this.blockMap);
    },
  },
};
</script>
