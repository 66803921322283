<template>
  <div :style="style">
    <iframe class="notion-image-inset" :src="src" :allow="embedAllow"></iframe>
  </div>
</template>

<script lang="ts">
import { Blockable, blockComputed } from '../../composable/blockable';

export default {
  name: 'NotionAsset',
  extends: Blockable,
  computed: {
    ...blockComputed,
    src() {
      return this.type === 'figma' ? this.properties.source[0][0] : this.f.display_source;
    },
    style() {
      const aspectRatio = this.f.block_aspect_ratio || this.f.block_height / this.f.block_width;
      return {
        paddingBottom: `${aspectRatio * 100}%`,
        position: 'relative',
      };
    },
  },
};
</script>
