import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["src", "allow"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle($options.style)
  }, [
    _createElementVNode("iframe", {
      class: "notion-image-inset",
      src: $options.src,
      allow: _ctx.embedAllow
    }, null, 8, _hoisted_1)
  ], 4))
}