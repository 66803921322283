<template>
  <block-renderer v-if="blockMap" :block-map="blockMap" :full-page="fullPage" :action="onAction" />
</template>

<script lang="ts">
import BlockRenderer from './block-renderer.vue';
import { getPageBlocks } from '../composable/api';
/**
 * @NOTE notion 실행 과정
 * id, handler중 무엇을 받았나로 서버(connect)에서 노션데이터를 받아올지 클라이언트에서 노션데이터를 받아올지가 결정됨.
 */

export default {
  name: 'NotionRenderer',
  components: {
    BlockRenderer,
  },
  props: {
    blockId: String,
    blockHandler: { type: Function },
    fullPage: { type: Boolean, default: false },
  },
  data() {
    return {
      blockMap: null,
    };
  },
  async created() {
    if (this.blockHandler) {
      const { data } = await this.blockHandler();
      this.blockMap = data;
    } else if (this.blockId) {
      this.loadPageBlocks();
    }
  },
  methods: {
    async loadPageBlocks() {
      this.blockMap = await getPageBlocks(this.blockId);
    },

    onAction(e) {
      this.$emit('action', e);
    },
  },
};
</script>
