<template>
  <div class="notion-simple-table-wrapper">
    <table class="notion-simple-table">
      <tbody>
        <slot></slot>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { Blockable } from '../../composable/blockable';

export default {
  name: 'NotionTable',
  extends: Blockable,
};
</script>
<style lang="scss" scoped>
.notion-simple-table-wrapper {
  display: block;
  overflow-x: auto;
  width: 100%;
}
</style>
