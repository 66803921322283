<template>
  <div :class="['notion-callout', blockColorClass(), blockColorClass('_co')]">
    <div>
      <NotionPageIcon v-bind="pass" />
    </div>
    <div class="notion-callout-text">
      <NotionTextRenderer :text="title" v-bind="pass" />
    </div>
  </div>
</template>

<script lang="ts">
import { Blockable } from '../../composable/blockable';
import NotionPageIcon from '../helpers/page-icon.vue';
import NotionTextRenderer from '../helpers/text-renderer.vue';

export default {
  name: 'NotionCallout',
  components: {
    NotionPageIcon,
    NotionTextRenderer,
  },
  extends: Blockable,
};
</script>
