<template>
  <div
    class="page-actions"
    :class="classNames"
    v-show="!isKeyboardVisible"
    :style="{ '--actions-height': `${actionsHeight}px` }"
  >
    <template v-if="isBubble">
      <div class="speech-bubble" v-show="isBubbleShow" @click="onClickBubble">
        <p>{{ bubbleMessage }}</p>
      </div>
    </template>
    <div ref="refPageActions" class="page-actions-content">
      <slot></slot>
    </div>
  </div>

  <resize-observer @notify="resizeActionHide" />
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import { useLayoutStore } from '@/stores/modules/layout';
import { useToastStore } from '@/stores/modules/toast';
import { createLogger } from '@/utils/logger';

const layout = useLayoutStore();
const toastModule = useToastStore();
const debug = createLogger('app:components:resources:layout:PageActions');
const props = defineProps({
  float: {
    type: Boolean,
    default: false,
  },
  class: {
    type: String,
    default: null,
  },
  isBubble: {
    type: Boolean,
    default: false,
  },
  bubbleMessage: {
    type: String,
    default: '',
  },
});
const classNames = computed(() => [props.float && 'floating', props.class ? props.class : '']);
const screen = ref(null);
const isKeyboardVisible = ref(false);
const refPageActions = ref(null);
const isBubbleShow = ref(props.isBubble);
const actionsHeight = ref(0);

const resizeActionHide = () => {
  layout.resize({});

  screen.value = {
    screenHeight: window.screen.height,
    availHeight: window.screen.availHeight,
    innerHeight: window.innerHeight,
    clientHeight: document.body.clientHeight,
    webviewHeight: window.visualViewport.height,
    bodyHeight: document.body.offsetHeight,
    testHeight: window.screen.availHeight * (document.body.clientHeight / window.screen.availHeight),
  };

  debug(
    'resize',
    screen.value.screenHeight * 0.7,
    screen.value.webviewHeight,
    screen.value.screenHeight * 0.7 < screen.value.testHeight,
  );

  if (screen.value.screenHeight * 0.7 < screen.value.testHeight) {
    isKeyboardVisible.value = false;
  } else {
    isKeyboardVisible.value = true;
  }
};
onMounted(() => {
  actionsHeight.value = refPageActions.value.clientHeight;
  toastModule.setMargin({ bottom: actionsHeight.value });
});
onBeforeUnmount(() => {
  toastModule.setMargin(null);
});

function onClickBubble() {
  isBubbleShow.value = false;
}
</script>

<style lang="scss" scoped>
.page-actions {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: $page-actions-index;
  // background-image: linear-gradient(180deg, rgba($color-background-page, 0) 1.56%, $color-background-page 13.28%);

  &.floating {
    position: static;
  }

  &-content {
    @include shadow-level(shadow3);
    @include safe-area-inset-bottom;
    background: $color-background-page;
    max-width: $max-content-width;
    margin: 0 auto;
    padding: unit(10) $page-side-margin;
    padding-bottom: calc($safe-area-bottom + unit(16));

    :deep() {
      button + button,
      button + a,
      a + button,
      a + a {
        margin-top: unit(12);
      }
    }
  }
}
</style>

<style lang="scss">
.keyboard-show {
  .page-actions {
    display: none;
  }
}

.speech-bubble {
  @include shadow-level(shadow3);
  @include text-variant(caption, $color-white);

  position: absolute;
  top: unit(-34);
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 16px;
  width: auto;
  min-width: 70%;
  background-color: $color-black;
  border-radius: $border-radius-side;

  // 꼬리
  &::after {
    content: '';
    position: absolute;
    bottom: -16px;
    left: calc(50% - 8px);
    width: 0;
    height: 0;
    border: 8px solid transparent;
    border-top-color: $color-black;
  }
}
</style>
