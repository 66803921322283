import { Buffer } from 'buffer';
import crypto from 'crypto';
import bigInt from 'big-integer';
import _ from 'lodash';
import paillier from './lib/paillier.js';

// Based on https://paillier.daylightingsociety.org/Paillier_Zero_Knowledge_Proof.pdf

// rEncrypt :: Paillier.PublicKey -> Message
// Fork from paillier-js - Create Paillier Encryption of message and return the random Paillier.R with the result
const rEncrypt = function ({ n, g }, message) {
  const _n2 = n.pow(2);
  let r;
  do {
    r = bigInt.randBetween(2, n);
  } while (r.leq(1));
  return [r, g.modPow(bigInt(message), _n2).multiply(r.modPow(n, _n2)).mod(_n2)];
};

// getCoprime :: Bits -> Number -> Number
// Generate a coprime number of target (their GCD should be 1)
const getCoprime = (target) => {
  const bits = Math.floor(Math.log2(target));
  while (true) {
    const lowerBound = bigInt(2)
      .pow(bits - 1)
      .plus(1);
    const size = bigInt(2).pow(bits).subtract(lowerBound);
    let possible = lowerBound.plus((bigInt as any).rand(bits)).or(1);
    const result = bigInt(possible);
    if (possible.gt(bigInt(2).pow(1024))) {
      return result;
    }
    while (target > 0) {
      [possible, target] = [target, possible.mod(target)];
    }
    if (possible.eq(bigInt(1))) {
      return result;
    }
  }
};

// Generate a candidate encryption and a Zero Knowledge proof that the candidate
// is among a set of valid candidates
const encryptWithProof = (publicKey, candidate, candidates, bits = 512) => {
  const as = [];
  const es = [];
  const zs = [];

  const [random, cipher] = rEncrypt(publicKey, candidate);

  const om = getCoprime(publicKey.n);
  const ap = om.modPow(publicKey.n, publicKey._n2);

  let mi = null;
  candidates.forEach((mk, i) => {
    const gmk = publicKey.g.modPow(bigInt(mk), publicKey._n2);
    const uk = cipher.times(gmk.modInv(publicKey._n2)).mod(publicKey._n2);
    if (candidate === mk) {
      as.push(ap);
      zs.push(null);
      es.push(null);
      mi = i;
    } else {
      const zk = getCoprime(publicKey.n);
      zs.push(zk);
      const ek = bigInt.randBetween(2, bigInt(2).pow(bits).subtract(1));
      es.push(ek);
      const zn = zk.modPow(publicKey.n, publicKey._n2);
      const ue = uk.modPow(ek, publicKey._n2);
      const ak = zn.times(ue.modInv(publicKey._n2)).mod(publicKey._n2);
      as.push(ak);
    }
  });

  const hash = crypto.createHash('sha256').update(as.join('')).digest('hex');

  const esum = es.filter(Boolean).reduce((acc, ek) => acc.plus(ek).mod(bigInt(2).pow(256)), bigInt(0));
  const ep = bigInt(hash, 16).subtract(esum).mod(bigInt(2).pow(256));
  const rep = random.modPow(ep, publicKey.n);
  const zp = om.times(rep).mod(publicKey.n);
  es[mi] = ep;
  zs[mi] = zp;

  const proof = [as, es, zs];

  return [cipher, proof];
};

// verifyProof :: Paillier.PublickKEy -> Paillier.Encryption, -> Proof -> [Message] -> Bool
// Verify a Zero Knowledge proof that an encrypted candidate is among a set of valid candidates
const verifyProof = (publicKey, cipher, [as, es, zs], candidates) => {
  const hash = crypto.createHash('sha256').update(as.join('')).digest('hex');

  const us = candidates.map((mk) => {
    const gmk = publicKey.g.modPow(mk, publicKey._n2);
    const uk = cipher.times(gmk.modInv(publicKey._n2)).mod(publicKey._n2);
    return uk;
  });

  const esum = es.reduce((acc, ek) => acc.plus(ek).mod(bigInt(2).pow(256)), bigInt(0));
  if (!bigInt(hash, 16).eq(esum)) {
    return false;
  }
  return zs.every((zk, i) => {
    const ak = as[i];
    const ek = es[i];
    const uk = us[i];
    const zkn = zk.modPow(publicKey.n, publicKey._n2);
    const uke = uk.modPow(ek, publicKey._n2);
    const akue = ak.times(uke).mod(publicKey._n2);
    return zkn.eq(akue);
  });
};

const generateCandidates = (str, bits) => {
  const buffers = Buffer.from(str);
  const keys = [];
  for (let i = 0; i < buffers.length; i++) {
    keys[i] = parseInt(buffers[i] as any, bits);
  }
  return _.shuffle(_.uniqBy(keys, (key) => key));
};

const encodePublicKey = (public_key) => {
  const serialize = {};

  for (const key in public_key) {
    serialize[key] = public_key[key].toString();
  }

  return Buffer.from(JSON.stringify(serialize)).toString('base64');
};

const decodePublicKey = (encoded_public_key) => {
  const json = Buffer.from(encoded_public_key, 'base64').toString('ascii');
  const serialize = JSON.parse(json);
  const public_key = {};

  for (const key in serialize) {
    public_key[key] = bigInt(serialize[key]);
  }

  return public_key;
};

const encodeCipher = (cipher) => {
  return Buffer.from(cipher.toString()).toString('base64');
};

const decodeCipher = (encoded_cipher) => {
  const cipher = Buffer.from(encoded_cipher, 'base64').toString('ascii');
  return bigInt(cipher);
};

const encodeProof = (proof) => {
  const serialize = [];

  for (let i = 0; i < proof.length; i++) {
    for (let j = 0; j < proof[i].length; j++) {
      if (!serialize[i]) {
        serialize[i] = [];
      }
      serialize[i][j] = (proof[i][j] && proof[i][j].toString()) || null;
    }
  }

  return Buffer.from(JSON.stringify(serialize)).toString('base64');
};

const decodeProof = (encoded_proof) => {
  const json = Buffer.from(encoded_proof, 'base64').toString('ascii');
  const serialize = JSON.parse(json);
  const proof = [];

  for (let i = 0; i < serialize.length; i++) {
    for (let j = 0; j < serialize[i].length; j++) {
      if (!proof[i]) {
        proof[i] = [];
      }
      proof[i][j] = (serialize[i][j] && bigInt(serialize[i][j])) || null;
    }
  }

  return proof;
};

export const zkp = {
  encryptWithProof,
  verifyProof,
  generateRandomKeys: paillier.generateRandomKeys,
  generateCandidates,
  encodePublicKey,
  decodePublicKey,
  encodeCipher,
  decodeCipher,
  encodeProof,
  decodeProof,
};
