import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotionCode = _resolveComponent("NotionCode")!

  return (_ctx.katex)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(), _createBlock(_resolveDynamicComponent('katex-element'), { expression: $options.equation }, null, 8, ["expression"]))
      ]))
    : (_openBlock(), _createBlock(_component_NotionCode, _mergeProps({ key: 1 }, _ctx.pass, {
        "override-lang": "latex",
        "override-lang-class": "language-latex"
      }), null, 16))
}