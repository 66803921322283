<template>
  <blockquote v-if="properties" class="notion-quote">
    <NotionTextRenderer :text="title" v-bind="pass" />
  </blockquote>
</template>

<script lang="ts">
import { Blockable } from '../../composable/blockable';
import NotionTextRenderer from '../helpers/text-renderer.vue';

export default {
  name: 'NotionQuote',
  components: { NotionTextRenderer },
  extends: Blockable,
};
</script>
