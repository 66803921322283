import _ from 'lodash';
import { v4 as UUID } from 'uuid';
import { createVNode, render } from 'vue';

/**
 * string을 16진수로 변환
 * @param {string} str - 변환할 문자열
 * @returns {string} - 입력받은 str을 변환한 16진수 값
 */
const stringToHex = (str: string) => {
  const arr: any[] = [];
  for (let i = 0; i < str.length; i += 1) {
    arr[i] = `00${str.charCodeAt(i).toString(16)}`.slice(-4);
  }
  return `\\u${arr.join('\\u')}`;
};

/**
 * 기기가 터치이벤트를 지원하는지 확인
 * @returns {boolean} - 터치 이벤트를 지원하면 true 아니면 false
 */
export const isTouchDevice = (): boolean => {
  let hasTouchScreen = false;
  if ('maxTouchPoints' in window.navigator) {
    hasTouchScreen = window.navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    hasTouchScreen = global.window.navigator.maxTouchPoints > 0;
  } else {
    const mQ = typeof window.matchMedia === 'function' && matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = global.window.navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }

  return hasTouchScreen;
};

/**
 * 값이 빈 값인지 확인
 * @param {any} value - 체크할 값
 * @returns {boolean} - null, undefined, string, array, object의 값이 비어있으면 true 아니면 false
 */
export const isEmpty = (value: any) => {
  if (value === null) {
    return true;
  }
  if (typeof value === 'undefined') {
    return true;
  }
  if (typeof value === 'string' && value === '') {
    return true;
  }
  if (Array.isArray(value) && value.length === 0) {
    return true;
  }
  if (
    typeof value === 'object' &&
    value.constructor.name === 'Object' &&
    Object.keys(value).length < 1 &&
    Object.getOwnPropertyNames(value).length < 1
  ) {
    if (typeof value === 'object' && value.constructor.name === 'String' && Object.keys(value).length < 1) {
      return true;
    }
  }

  return false;
};

/**
 * 객체에서 빈 값을 제거
 * @param {object} obj - 빈 값을 제거할 객체
 * @returns {object} - 빈 값이 제거된 객체
 */
export const removeEmpty = (obj: any) =>
  Object.entries(obj)
    .filter(([_, v]) => v != null)
    .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});

export const getLoadedScript = (url: string): boolean => {
  const scripts = window.document.getElementsByTagName('script');
  let value: any = null;

  for (let i = 0; i < scripts.length; i += 1) {
    if ((scripts[i].src ?? '').includes(url)) {
      value = scripts[i];
      break;
    }
  }

  return value;
};

/**
 * 지정된 키를 기준으로 객체 배열 정렬
 * @param {Array<Object>} items - 정렬할 객체 배열
 * @param {*} key - 객체 정렬 기준이 되는 키
 * @param {'ASC' | 'DESC'} [order='DESC'] - 정렬순서 [오름차순 : 'ASC' 내림차순: 'DESC']
 * @returns {Array<Object>} - 정렬된 객체 배열
 */
export const sortForKey = (items: Array<any>, key: any, order: 'ASC' | 'DESC' = 'DESC') => {
  items.sort((a, b) => {
    if (order === 'ASC') {
      return +(a[key] < b[key]) || +(a[key] === b[key]) - 1;
    }

    return +(a[key] > b[key]) || +(a[key] === b[key]) - 1;
  });
};

/**
 * 키-값 객체를 쿼리스트링으로 만듬
 * @param {Object} options - 쿼리스트링으로 생성할 객체
 * @returns {string} - 생성된 쿼리스트링
 */
export function generateQueryString(options: any) {
  let queryString = '?';

  Object.keys(options).forEach((o) => {
    if (options[o] || options[o] === 0) {
      queryString += `${o}=${options[o]}&`;
    }
  });

  return queryString;
}

/**
 * JSON string을 객체로 parse
 * @param {string} jsonString - parse할 JSON string
 * @param {object} [defaultValue={}] - parse 실패 시 반환할 객체
 * @returns {object} - 객체로 parse된 JSON string
 */
export function parseJSON(jsonString, defaultValue = {}) {
  if (jsonString === 'null' || jsonString === null || typeof jsonString === 'undefined') {
    return defaultValue;
  }

  let result = jsonString && _.isObject(jsonString) ? jsonString : defaultValue;

  if (_.isString(jsonString)) {
    try {
      result = JSON.parse(jsonString || '{}');
    } catch (e) {
      // console.error("parseJSON Error: ", e, jsonString);
      result = defaultValue;
    }
  }
  return result;
}

export function ufirst(s: string) {
  if (s.length === 0) {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.toLowerCase().slice(1);
}

export function generateHTMLFromVue(slotContent: any) {
  const div = document.createElement('div');
  const vnode = createVNode('div', {}, slotContent);
  render(vnode, div);
  return div.innerHTML;
}

export function uuid() {
  return UUID().replace(/-/gi, '');
}

export function formatPhoneNumber(input) {
  if (!input) {
    return '';
  }

  const cleanInput = input.replaceAll(/[^0-9]/g, '');
  let result = '';
  const length = cleanInput.length;
  if (length === 8) {
    result = cleanInput.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else if (cleanInput.startsWith('02') && (length === 9 || length === 10)) {
    result = cleanInput.replace(/(\d{2})(\d{3,4})(\d{4})/, '$1-$2-$3');
  } else if (!cleanInput.startsWith('02') && (length === 10 || length === 11)) {
    result = cleanInput.replace(/(\d{3})(\d{3,4})(\d{4})/, '$1-$2-$3');
  } else {
    result = undefined;
  }
  return result;
}
