<template>
  <div class="alert-banner">
    <router-link :to="{ name: 'IntroSpaceRegister' }" @click="onClickLink">
      <div class="message-wrapper">
        <svg-icon name="sign-caution" />
        <div>
          <p
            v-html="$t('공간 등록을 하지 않으면 <span>매 월 최소 11만 원</span>을 놓쳐요. 서둘러 공간을 등록해 주세요')"
          ></p>
          <progress-bar :progress="progress" />
        </div>
      </div>
    </router-link>

    <button type="button" @click="onClickAlertBannerClose">
      <svg-icon name="close" />
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import SvgIcon from '@/components/commons/SvgIcon.vue';
import ProgressBar from '@/components/commons/utils/ProgressBar.vue';
import { useAnalytics } from '@/plugins/analytics';
import { $t } from '@/plugins/locale';
import { useSpaceStore } from '@/stores/modules/space';

const analytics = useAnalytics();
const spaceStore = useSpaceStore();

const emit = defineEmits(['close']);
const progress = computed(() => {
  const draftSpace = spaceStore.getCached('draft_space');
  if (!draftSpace) {
    return 0;
  }

  const { extras } = draftSpace;
  return extras?.draft_progress;
});

function onClickAlertBannerClose() {
  analytics.event('select_register_space_continue_close_button');
  emit('close');
}

function onClickLink() {
  analytics.event('select_register_space_continue_banner');
}
</script>

<style lang="scss" scoped>
.alert-banner {
  position: fixed;
  top: $page-header-height;
  left: 50%;
  right: 0;
  z-index: 100;
  padding: unit(20) unit(24) unit(8) unit(20);
  width: 100%;
  max-width: $max-content-width;
  background-color: $color-primary-lighten-5;
  transition: transform 0.3s;
  transform: translate(-50%, 0);

  a {
    display: block;
  }

  .message-wrapper {
    display: flex;
    align-items: start;
    gap: unit(5);
    margin-right: unit(17);

    .svg-icon {
      flex-shrink: 0;
      margin-top: unit(4);
      font-size: unit(40);
    }
  }

  p {
    font-size: unit(16);
    color: $color-gray-64;
    line-height: 1.5;
    letter-spacing: unit(-0.4);

    :deep() {
      span {
        font-weight: 700;
        color: $color-state-alert;
      }
    }
  }

  button {
    position: absolute;
    top: unit(20);
    right: unit(24);

    .svg-icon {
      font-size: unit(24);
      color: $color-gray-160;
    }
  }

  :deep() {
    .progress-wrapper {
      margin-bottom: 0;
      .bar {
        background-color: $color-primary-lighten-4;
      }

      .percent {
        color: $color-primary;
      }
    }
  }
}
</style>
