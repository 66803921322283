import NaverMap from './lib/naver-map';
import { ProxyMap } from './lib/proxy-map';
import TMap from './lib/t-map';
import { MapInterface, MapType, getMapType } from './typings';
// import GoogleMap from "./lib/google-map";

export class MapManager {
  private _timeout?: null | ReturnType<typeof setTimeout>;
  private _mapInstance?: MapInterface = null;
  private _proxyMapInstance: ProxyMap = new ProxyMap({});

  private _mapType: MapType = MapType.UNKNOWN;

  get map() {
    return this.instance.map;
  }

  get mapType() {
    return this._mapType;
  }

  get instance() {
    if (!this._mapInstance || !this._mapInstance.initiated) {
      return this._proxyMapInstance;
    }

    return this._mapInstance;
  }

  get initiated() {
    return this._mapInstance?.initiated || false;
  }

  get loaded() {
    return this._mapInstance?.loaded || false;
  }

  constructor() {}

  setup(mapType: MapType | string, options: any) {
    this._proxyMapInstance.clear();
    this._mapType = getMapType(mapType);

    if (this._mapType === MapType.TMAP) {
      this._mapInstance = new TMap(options);
    } else {
      this._mapInstance = new NaverMap(options);
    }
  }

  async loadView(el: HTMLElement, options: any = {}) {
    const map = await this._mapInstance?.loadView(el, options);
    this._proxyMapInstance.execute(this._mapInstance);
    return map;
  }

  destroy() {
    this.instance.destroy();

    this._mapInstance = null;
    this._mapType = MapType.UNKNOWN;
  }

  delay(milliseconds: number) {
    if (this._timeout) {
      clearTimeout(this._timeout);
      this._timeout = null;
    }

    const chaining: ProxyMap = new ProxyMap({});

    this._timeout = setTimeout(() => {
      chaining.execute(this._mapInstance);
    }, milliseconds);

    return chaining;
  }

  private static sharedInstance: MapManager = null;

  static shared() {
    if (!MapManager.sharedInstance) {
      MapManager.sharedInstance = new MapManager();
    }

    return MapManager.sharedInstance;
  }

  convertTM128ToLatLng(mapx, mapy) {
    const latLng = this._mapInstance.convertTM128ToLatLng(mapx, mapy);

    return latLng;
  }
}

const plugin = {
  install: (app, options = {}) => {},
};

export default plugin;
