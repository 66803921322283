import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notion-quote"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotionTextRenderer = _resolveComponent("NotionTextRenderer")!

  return (_ctx.properties)
    ? (_openBlock(), _createElementBlock("blockquote", _hoisted_1, [
        _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
      ]))
    : _createCommentVNode("", true)
}