/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  CreateReportDto,
  SpaceCreateDto,
  SpaceProviderCreateDto,
  SpaceProviderUpdateDto,
  SpaceUpdateDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class SpaceApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceControllerCreate
   * @summary 공간 등록
   * @request POST:/v1/spaces
   * @secure
   */
  spaceControllerCreate = (data: SpaceCreateDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceControllerList
   * @summary 공간 리스트 조회
   * @request GET:/v1/spaces
   * @secure
   */
  spaceControllerList = (
    query?: {
      page?: number;
      limit?: number;
      myItems?: string;
      sort_type?: string;
      order_type?: string;
      featured?: string;
      host_user_id?: string;
      space_reqeust_id?: string;
      status?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, void>({
      path: `/v1/spaces`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceControllerFindOne
   * @summary 공간 단일 조회
   * @request GET:/v1/spaces/{id}
   * @secure
   */
  spaceControllerFindOne = (id: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/${id}`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceControllerUpdate
   * @summary 공간 업데이트
   * @request PUT:/v1/spaces/{id}
   * @secure
   */
  spaceControllerUpdate = (id: number, data: SpaceUpdateDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceControllerRemove
   * @summary 공간 삭제
   * @request DELETE:/v1/spaces/{id}
   * @secure
   */
  spaceControllerRemove = (id: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceControllerSpaceReexamine
   * @summary 재심사 요청
   * @request PUT:/v1/spaces/{id}/reexamine
   * @secure
   */
  spaceControllerSpaceReexamine = (id: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/${id}/reexamine`,
      method: 'PUT',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceControllerBookmarkList
   * @summary 공간 관심 리스트
   * @request GET:/v1/spaces/bookmark
   * @secure
   */
  spaceControllerBookmarkList = (query?: { page?: number; limit?: number }, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/bookmark`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceControllerBookmarkLinked
   * @summary 공간 관심 추가
   * @request POST:/v1/spaces/{id}/bookmark
   * @secure
   */
  spaceControllerBookmarkLinked = (id: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/${id}/bookmark`,
      method: 'POST',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceControllerBookmarkUnLinked
   * @summary 공간 관심 취소
   * @request DELETE:/v1/spaces/{id}/bookmark
   * @secure
   */
  spaceControllerBookmarkUnLinked = (id: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/${id}/bookmark`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceControllerReport
   * @summary 공간 신고하기
   * @request POST:/v1/spaces/{id}/report
   * @secure
   */
  spaceControllerReport = (id: number, data: CreateReportDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/${id}/report`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceControllerSpaceJudgeApproveCheck
   * @summary 공간 심사 승인 체크
   * @request PUT:/v1/spaces/{id}/judge-approve-check
   * @secure
   */
  spaceControllerSpaceJudgeApproveCheck = (id: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/${id}/judge-approve-check`,
      method: 'PUT',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceProviderControllerGetMySpaceList
   * @summary 호스트 본인 공간 리스트
   * @request GET:/v1/spaces/provider
   * @secure
   */
  spaceProviderControllerGetMySpaceList = (params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/provider`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceProviderControllerCreate
   * @summary 호스트 공간 생성
   * @request POST:/v1/spaces/provider
   * @secure
   */
  spaceProviderControllerCreate = (data: SpaceProviderCreateDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/provider`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceProviderControllerUpdate
   * @summary 호스트 공간 수정
   * @request PUT:/v1/spaces/provider/{id}
   * @secure
   */
  spaceProviderControllerUpdate = (id: number, data: SpaceProviderUpdateDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/provider/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceProviderControllerDelete
   * @summary 호스트 공간 제거
   * @request DELETE:/v1/spaces/provider/{id}
   * @secure
   */
  spaceProviderControllerDelete = (id: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/provider/${id}`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags space-api
   * @name SpaceProviderControllerFindOneById
   * @summary 호스트 공간 단일 조회
   * @request GET:/v1/spaces/provider/{id}
   * @secure
   */
  spaceProviderControllerFindOneById = (id: number, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/spaces/provider/${id}`,
      method: 'GET',
      secure: true,
      ...params,
    });
}
