<template>
  <div class="progress-wrapper" :class="state.classes" :style="state.styles">
    <div class="bar"></div>
    <span class="percent">{{ props.progress }}%</span>
  </div>
</template>
<script lang="ts" setup>
import { computed, reactive } from 'vue';

const props = defineProps({
  progress: {
    type: Number,
    required: true,
  },
});
const state = reactive({
  styles: computed(() => ({
    '--percent': `${props.progress}%`,
  })),
  classes: computed(() => ({
    active: props.progress > 0,
  })),
});
</script>
<style lang="scss" scoped>
.progress-wrapper {
  @include flexbox();
  gap: unit(8);
  margin-bottom: unit(6);

  .bar {
    position: relative;
    width: 100%;
    height: unit(4);
    background-color: #d9d9d9;
    border-radius: $border-radius-side;
    overflow: hidden;

    &::after {
      position: absolute;
      inset: 0;
      content: '';
      display: block;
      width: var(--percent);
      height: 100%;
      background-color: $color-primary;
      border-radius: $border-radius-side;
      transition: width 0.3s;
    }
  }

  .percent {
    @include text-variant('body2', #a4a4a4);
  }

  &.active {
    .percent {
      color: $color-primary;
    }
  }
}
</style>
