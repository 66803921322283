/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { UpdateProfileDto, UserRegisterByPhoneDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class UserApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags user-api
   * @name UserControllerRegisterPhone
   * @summary 전화번호로 유저 등록
   * @request POST:/v1/users/register/phone
   */
  userControllerRegisterPhone = (data: UserRegisterByPhoneDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/users/register/phone`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api
   * @name UserControllerUpdateProfile
   * @summary 프로필 수정
   * @request PUT:/v1/users
   * @secure
   */
  userControllerUpdateProfile = (data: UpdateProfileDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/users`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api
   * @name UserControllerMyProfile
   * @summary 내 정보 조회
   * @request GET:/v1/users/profile
   * @secure
   */
  userControllerMyProfile = (params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/users/profile`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api
   * @name UserControllerCheckByDisplayName
   * @summary 닉네임 중복 체크
   * @request GET:/v1/users/check/display_name
   * @secure
   */
  userControllerCheckByDisplayName = (query: { display_name: string }, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/users/check/display_name`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api
   * @name UserControllerUnregister
   * @summary 회원 탈퇴
   * @request DELETE:/v1/users/unregister
   * @secure
   */
  userControllerUnregister = (params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/users/unregister`,
      method: 'DELETE',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api
   * @name UserControllerMypageInfo
   * @summary 마이페이지 정보
   * @request GET:/v1/users/mypage
   * @secure
   */
  userControllerMypageInfo = (params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/users/mypage`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags user-api
   * @name UserControllerGetUserInfo
   * @summary 유저 정보
   * @request GET:/v1/users/info
   * @secure
   */
  userControllerGetUserInfo = (params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/users/info`,
      method: 'GET',
      secure: true,
      ...params,
    });
}
