import { renderSlot as _renderSlot, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "notion-column",
      style: _normalizeStyle($options.columnStyle)
    }, [
      _renderSlot(_ctx.$slots, "default")
    ], 4),
    _createElementVNode("div", {
      class: "notion-spacer",
      style: _normalizeStyle($options.spacerStyle)
    }, null, 4)
  ], 64))
}