export enum Language {
  'KOREAN' = 'ko', // 한국어
  'ENGLISH' = 'en', // 영어
  'SIMPLIFIED_CHINESE' = 'zh-Hans', // 간체체
  'TRADITIONAL_CHINESE' = 'zh-Hant', // 번체체
  'JAPANESE' = 'ja', // 일본어
  'VIETNAMESE' = 'vn', // 베트남어
  'RUSSIAN' = 'ru', // 러시아어
  'POLISH' = 'pl', // 폴란드어
  'SPANISH' = 'es', // 스페인어
  'FRENCH' = 'fr', // 프랑스어
  'GERMAN' = 'de', // 독일어
  'ITALIAN' = 'it', // 이탈리아어
  'PORTUGUESE' = 'pt', // 포르투갈어
  'SYSTEM' = 'system', // 시스템
}

export const MappingLanguages = {
  'ko': Language.KOREAN,
  'en': Language.ENGLISH,
  'zh-Hans': Language.SIMPLIFIED_CHINESE,
  'zh-Hant': Language.TRADITIONAL_CHINESE,
  'ja': Language.JAPANESE,
  'vn': Language.VIETNAMESE,
  'ru': Language.RUSSIAN,
  'pl': Language.POLISH,
  'es': Language.SPANISH,
  'fr': Language.FRENCH,
  'de': Language.GERMAN,
  'it': Language.ITALIAN,
  'pt': Language.PORTUGUESE,
  'system': Language.SYSTEM,
};

export const MappingDocumentLanguages = {
  [Language.KOREAN]: 'ko-KR',
  [Language.ENGLISH]: 'en-US',
  [Language.SIMPLIFIED_CHINESE]: 'zh-Hans-CN',
  [Language.TRADITIONAL_CHINESE]: 'zh-Hant-TW',
  [Language.JAPANESE]: 'ja-JP',
  [Language.VIETNAMESE]: 'vi-VN',
  [Language.RUSSIAN]: 'ru-RU',
  [Language.POLISH]: 'pl-PL',
  [Language.SPANISH]: 'es-ES',
  [Language.FRENCH]: 'fr-FR',
  [Language.GERMAN]: 'de-DE',
  [Language.ITALIAN]: 'it-IT',
  [Language.PORTUGUESE]: 'pt-PT',
};

export const MappingCountryLanguages = {
  [Language.KOREAN]: 'KR',
  [Language.ENGLISH]: 'US',
  [Language.SIMPLIFIED_CHINESE]: 'CN',
  [Language.TRADITIONAL_CHINESE]: 'TW',
  [Language.JAPANESE]: 'JP',
  [Language.VIETNAMESE]: 'VN',
  [Language.RUSSIAN]: 'RU',
  [Language.POLISH]: 'PL',
  [Language.SPANISH]: 'ES',
  [Language.FRENCH]: 'FR',
  [Language.GERMAN]: 'DE',
  [Language.ITALIAN]: 'IT',
  [Language.PORTUGUESE]: 'PT',
};

export const MappingGlobalLanguages = {
  [Language.KOREAN]: '한국어',
  [Language.ENGLISH]: 'English',
  [Language.SIMPLIFIED_CHINESE]: '简体中文',
  [Language.TRADITIONAL_CHINESE]: '繁體中文',
  [Language.JAPANESE]: '日本語',
  // [Language.VIETNAMESE]: 'Tiếng Việt',
  // [Language.RUSSIAN]: 'Русский',
  // [Language.POLISH]: 'Polskie',
  // [Language.SPANISH]: 'Español',
  // [Language.FRENCH]: 'Français',
  // [Language.GERMAN]: 'Deutsch',
  // [Language.ITALIAN]: 'Italiano',
  // [Language.PORTUGUESE]: 'Português',
};
