/*eslint no-implicit-globals: "error"*/

import debug from 'debug';
debug.enable(process.env.VUE_APP_DEBUG);

/* eslint-disable-next-line */
if (!window['__LOGGER_START_TIME__']) {
  /* eslint-disable-next-line */
  window['__LOGGER_START_TIME__'] = new Date();
}

/* eslint-disable-next-line */
if (!window['__LOGGER_FIRST_TIME__']) {
  /* eslint-disable-next-line */
  window['__LOGGER_FIRST_TIME__'] = new Date();

  if (process.env.NODE_ENV !== 'production') {
    // console.log('로그보는 법 : 네임스페이스, 시작 후 경과 시간, 이전 로그 대비경과 시간, 로그 메세지, 네임스페이스별 로그 경과 시간, 데이타');
    // console.log( '로그보는 법 : 네임스페이스, 실행 시간, 로그 시간차, 로그 메세지, 네임스페이스별 로그 시간차, 데이타');
  }
}

const getDatetime = function () {
  const currentTime = new Date();

  /* eslint-disable-next-line */
  var globalDiffTime = currentTime.getTime() - window['__LOGGER_START_TIME__'].getTime();
  /* eslint-disable-next-line */
  var firstDiffTime = currentTime.getTime() - window['__LOGGER_FIRST_TIME__'].getTime();

  /* eslint-disable-next-line */
  window['__LOGGER_START_TIME__'] = new Date();

  return `${(firstDiffTime * 0.001).toFixed(3)}s ${globalDiffTime}ms`;
  // var dateOptions = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false, };
  // return `[${currentTime.toLocaleString('en-CA', dateOptions)}] ${globalDiffTime}ms`;
};

const origFormatArgs = debug.formatArgs;

debug.formatArgs = function (args) {
  const { namespace: name } = this;

  // requires access to "this"
  // example: prepend something to arguments[0]
  args[0] = `${getDatetime.call(this)} ${args[0]}`;

  // call original implementation
  origFormatArgs.call(this, args);
};

export function createLogger(namespace) {
  const logger = debug(namespace);
  return logger;
}

export default debug;
