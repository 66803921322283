<template>
  <details class="notion-toggle">
    <summary><NotionTextRenderer :text="title" v-bind="pass" /></summary>
    <div><slot></slot></div>
  </details>
</template>

<script lang="ts">
import { Blockable } from '../../composable/blockable';
import NotionTextRenderer from '../helpers/text-renderer.vue';

export default {
  name: 'NotionToggle',
  components: { NotionTextRenderer },
  extends: Blockable,
};
</script>
