<template>
  <header class="notion-page-header">
    <div class="notion-nav-breadcrumbs">
      <!-- todo: breadcrumbs -->
    </div>
  </header>
</template>

<script lang="ts">
import { Blockable } from '../../composable/blockable';
import Decorator from '../blocks/decorator.vue';

export default {
  name: 'NotionPageHeader',
  components: { Decorator },
  extends: Blockable,
};
</script>
