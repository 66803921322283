<template>
  <NotionRenderer v-bind="pass" :block-map="blockMap" :content-id="referencePointerId" />
</template>

<script lang="ts">
import { Blockable, blockComputed } from '../../composable/blockable';

export default {
  name: 'NotionSyncPointer',
  extends: Blockable,
  computed: {
    ...blockComputed,
    referencePointerId() {
      return this.format?.transclusion_reference_pointer?.id;
    },
  },
};
</script>
