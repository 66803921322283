<template>
  <template v-if="format && format.toggleable === true">
    <details class="notion-toggle">
      <summary class="notion-h1" v-if="type === 'header'">
        <NotionTextRenderer :text="title" v-bind="pass" />
      </summary>
      <summary class="notion-h2" v-if="type === 'sub_header'">
        <NotionTextRenderer :text="title" v-bind="pass" />
      </summary>
      <summary class="notion-h3" v-if="type === 'sub_sub_header'">
        <NotionTextRenderer :text="title" v-bind="pass" />
      </summary>
      <div><slot></slot></div>
    </details>
  </template>
  <template v-else>
    <h1 class="notion-h1" v-if="type === 'header'">
      <NotionTextRenderer :text="title" v-bind="pass" />
    </h1>
    <h2 class="notion-h2" v-else-if="type === 'sub_header'">
      <NotionTextRenderer :text="title" v-bind="pass" />
    </h2>
    <h3 class="notion-h3" v-else-if="type === 'sub_sub_header'">
      <NotionTextRenderer :text="title" v-bind="pass" />
    </h3>
  </template>
</template>

<script lang="ts">
import { Blockable } from '../../composable/blockable';
import NotionTextRenderer from '../helpers/text-renderer.vue';

export default {
  name: 'NotionHeader',
  components: { NotionTextRenderer },
  extends: Blockable,
};
</script>
