import { defineStore } from 'pinia';
import { createLogger } from '@/utils/logger';

const debug = createLogger('app:stores:layout');

enum LAYOUT_MODE {
  UNSET = 'unset',
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

interface LayoutState {
  layoutMode: string;
  screen: { width: number; height: number };
  scroll: { x: number; y: number };
  keyboardHeight: number;
}

export const useLayoutStore = defineStore({
  id: 'layout',
  state: (): LayoutState => ({
    layoutMode: LAYOUT_MODE.UNSET,
    screen: {
      width: window.screen.width,
      height: window.screen.height,
    },
    scroll: { x: 0, y: 0 },
    keyboardHeight: 0,
  }),
  getters: {
    currentLayoutMode: (state) => {
      if (state.layoutMode === LAYOUT_MODE.UNSET) {
        throw new Error('UNSET LAYOUT MODE STATE');
      }
      return state.layoutMode;
    },
  },
  actions: {
    init() {
      debug('init');
      return new Promise(async (resolve, reject) => {
        try {
          // 설정된 값이 아직 없음
          this.updateLayoutMode();
          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    unit(size) {
      const unitRatio = 0.0625;

      let unitSize = 16;

      if (this.screen.width >= 568) {
        unitSize = 20;
      } else if (this.screen.width >= 425) {
        unitSize = 18;
      } else if (this.screen.width >= 375) {
        unitSize = 16;
      } else if (this.screen.width >= 0) {
        unitSize = 14;
      }

      debug('unit', this.screen.width, size, unitSize, unitSize * unitRatio * size);
      return unitSize * unitRatio * size;
    },
    updateLayoutMode() {
      debug('updateLayoutMode');
      const screenWidth = document.body.clientWidth || 0;

      let layoutMode = LAYOUT_MODE.DESKTOP;
      if (screenWidth < 768) {
        layoutMode = LAYOUT_MODE.MOBILE;
      } else if (screenWidth < 1024) {
        layoutMode = LAYOUT_MODE.TABLET;
      }

      if (this.layoutMode !== layoutMode) {
        this.layoutMode = layoutMode;
      }
    },
    resize(payload) {
      debug('resize', payload);
      return new Promise(async (resolve, reject) => {
        try {
          this.screen.width = window.window.screen.width;
          this.screen.height = window.window.screen.height;
          this.updateLayoutMode();
          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    setKeyboardHeight(height) {
      this.keyboardHeight = height;
    },
  },
});
