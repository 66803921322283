import { MapInterface, MapMarkerOptions } from './../typings/index';

export class ProxyMap implements MapInterface {
  private _callActions: any[] = [];
  private _zoom: number = 17;
  private _center: any = { latitude: 126.734086, longitude: 37.413294 };

  public isDragging: boolean = false;

  public loaded: boolean = false;
  public initiated: boolean = false;
  public map: any = null;

  clear() {
    this._callActions = [];
  }

  execute(instance: MapInterface) {
    this._callActions.forEach((item) => {
      try {
        const result = instance[item.action].apply(instance, item.args);
        if (item.resolve) {
          item.resolve(result);
        }
        return result;
      } catch (e) {
        if (item.reject) {
          item.reject(this);
        } else {
          console.error(e);
        }
      }
    });
    this._callActions = [];
  }

  constructor(options: any) {}

  loadView(el: HTMLElement, options: any = {}) {
    return new Promise((resolve, reject) => {
      this._callActions.push({ action: 'loadView', args: arguments, async: true, resolve, reject });
    });
  }

  load() {
    this._callActions.push({ action: 'load', args: arguments });
  }

  addMapEventListener(eventName: string, callback: Function) {
    this._callActions.push({ action: 'addMapEventListener', args: arguments });
  }

  removeMapEventListener(eventName: string) {
    this._callActions.push({ action: 'removeMapEventListener', args: arguments });
  }

  triggerEvent(target, eventName, eventObject) {
    this._callActions.push({ action: 'triggerEvent', args: arguments });
  }

  addMarkerEventListener(marker: any, eventName: string, callback: Function) {
    this._callActions.push({ action: 'addMarkerEventListener', args: arguments });
  }

  removeMarkerEventListener(marker: any, eventName: string) {
    this._callActions.push({ action: 'removeMarkerEventListener', args: arguments });
  }

  createMarker(options: MapMarkerOptions, callback) {
    this._callActions.push({ action: 'createMarker', args: arguments });
  }

  updateMarker(marker: any, options: MapMarkerOptions) {
    this._callActions.push({ action: 'updateMarker', args: arguments });
  }

  removeMarker({ id }) {
    this._callActions.push({ action: 'removeMarker', args: arguments });
  }

  clearMarkers() {
    this._callActions.push({ action: 'clearMarkers', args: arguments });
  }

  updateMarkers() {
    this._callActions.push({ action: 'updateMarkers', args: arguments });

    const data = {
      hidden: {},
      shown: {},
    };
    return data;
  }

  isInBounds(key) {
    this._callActions.push({ action: 'isInBounds', args: arguments });
    return false;
  }

  getZoom() {
    return this._zoom;
  }

  setZoom(value) {
    this._zoom = value;
    this._callActions.push({ action: 'setZoom', args: arguments });
  }

  getCenter() {
    return this._center;
  }

  setCenter(coords) {
    const { latitude, longitude } = coords;

    this._center.latitude = latitude;
    this._center.longitude = longitude;
    this._callActions.push({ action: 'setCenter', args: arguments });
  }

  panTo(coords) {
    const { latitude, longitude } = coords;

    this._center.latitude = latitude;
    this._center.longitude = longitude;

    this._callActions.push({ action: 'panTo', args: arguments });
  }

  getRadius() {
    return 0.0;
  }

  setSize(width, height) {
    this._callActions.push({ action: 'setSize', args: arguments });
  }

  resize() {
    this._callActions.push({ action: 'resize', args: arguments });
  }

  destroy() {
    this._callActions.push({ action: 'destroy', args: arguments });
  }

  getDistanceBetween(pointA, pointB) {
    return NaN;
  }

  convertTM128ToLatLng() {
    return {
      x: 126.9769076,
      y: 37.5704116,
      _lat: 37.5704116,
      _lng: 126.9769076,
    };
  }
}
