<template>
  <div class="animation lottie">
    <div ref="animation" v-show="animating" class="lottie-container" :style="style"></div>
    <div v-show="!animating" class="lottie-still">
      <slot ref="still" name="still"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import lottie from 'lottie-web';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

const props = defineProps({
  animationData: {
    type: Object,
    required: true,
  },
  loop: {
    type: Boolean,
    default: true,
  },
  autoplay: {
    type: Boolean,
    default: true,
  },
  animating: {
    type: Boolean,
    default: true,
  },
  width: {
    type: [Number, String],
    default: '',
  },
  height: {
    type: [Number, String],
    default: '',
  },
});

const lottieAnimation = ref(null);
const animation = ref(null);

const style = computed(() => {
  return {
    width: props.width ? `${props.width}px` : '100%',
    height: props.height ? `${props.height}px` : '100%',
    // overflow: "hidden",
    // margin: "0 auto",
  };
});

// const rendererSettings = computed(() => {
//   let canvasContext = refs.canvas.getContext('2d');
//   return {
//     context: canvasContext, // the canvas context
//     // preserveAspectRatio: 'xMinYMin slice', // Supports the same options as the svg element's preserveAspectRatio property
//     clearCanvas: false,
//     progressiveLoad: true, // Boolean, only svg renderer, loads dom elements when needed. Might speed up initialization for large number of elements.
//     hideOnTransparent: true, //Boolean, only svg renderer, hides elements when opacity reaches 0 (defaults to true)
//   };
// });

onMounted(() => {
  lottieAnimation.value = lottie.loadAnimation({
    name: `lottie-${+new Date()}`,
    container: animation.value,
    renderer: 'svg',
    loop: props.loop !== false,
    autoplay: props.autoplay !== false,
    animationData: props.animationData,
    // rendererSettings: this.rendererSettings
  });

  lottieAnimation.value.addEventListener('complete', onComplete);
});

onBeforeUnmount(() => {
  if (lottieAnimation.value) {
    lottieAnimation.value.removeEventListener('complete', onComplete);

    lottieAnimation.value.stop();
    lottieAnimation.value.destroy();
    lottieAnimation.value = null;
  }
});

function onComplete() {}

function onSpeed(value = 1) {
  lottieAnimation.value.setSpeed(value);
}
</script>
