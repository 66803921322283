import { useSessionStore } from '@/stores/modules/session';

class PermissionGuard {
  _permissionHandler: Function = () => {};

  setPermissionHandler(handler: Function) {
    this._permissionHandler = handler;
  }

  clearPermissionHandler() {
    this._permissionHandler = () => {};
  }

  get permissionHandler() {
    return this._permissionHandler;
  }

  // 로그인 여부 체크
  async checkPermission(to: any, from: any) {
    this.clearPermissionHandler();
    const { auth } = to.meta ?? {};

    const session = useSessionStore();
    await session.init();

    const isLoggedIn = session.isLogged;
    const isGuideSpaceRegister = session.getRedirectGuideSpaceRegister();

    // 공간 등록 가이드 페이지로 이동시 로그인 되어있다면 등록 소개 페이지로 이동 처리
    if (isLoggedIn && to.name === 'GuideSpaceRegister') {
      return this.setPermissionHandler((next: Function) =>
        next({
          name: 'IntroSpaceRegister',
        }),
      );
    }

    // 로그인이 되어있지 않다면 로그인 페이지로 이동 처리
    if (auth && to.name !== 'GuideSpaceRegister' && to.name !== 'PassportByPhone') {
      if (!isLoggedIn) {
        return this.setPermissionHandler((next: Function) => {
          const queryString = parseQuery(to.query);
          next({
            name: isGuideSpaceRegister ? 'GuideSpaceRegister' : 'PassportByPhone',
            query: { redirect: encodeURIComponent(to.path + '?' + queryString) },
          });
        });
      }
    }

    return this.setPermissionHandler((next: Function) => next(true));
  }
}

function parseQuery(query) {
  return '';
}

const guard = new PermissionGuard();
export default guard;
