export default [
  {
    path: '/message',
    alias: '/chat',
    component: () => import('@/pages/message/PageChatList.vue'),
    name: 'ChatList',
    meta: { title: '채팅목록', auth: true },
  },
  {
    path: '/message/:id',
    alias: '/chat/:id',
    component: () => import('@/pages/message/PageChat.vue'),
    name: 'Chat',
    meta: { title: '채팅', auth: true },
  },
];
