import loadScript from 'load-script';
import { WNInterface, useWNInterface } from '@/plugins/vue-wni';
import { AnalyticsModule } from '..';

declare global {
  interface Window {
    dataLayer: any;
    fbAsyncInit: any;
    FB: any;
  }
}

export default class FacebookEvents implements AnalyticsModule {
  private _appId: string;
  private _mappingEvents: any;
  private _wni: WNInterface;
  public isLoaded: boolean = false;

  constructor({ appId }) {
    this._appId = appId;
    this._mappingEvents = {
      select_book_space_button: 'select_book_space_button',
      complete_register_space_basic: 'complete_register_space_basic',
      complete_register_space_additional: 'complete_register_space_additional',
      complete_create_seek_space: 'complete_create_seek_space',
      // select_suggest_space: 'select_suggest_space',
      complete_suggest_space: 'complete_suggest_space',
    };
    this._wni = useWNInterface();
  }

  _loadScript() {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: this._appId,
        xfbml: true,
        version: 'v19.0',
      });
      window.FB.AppEvents.logPageView();
    };

    const getScriptUrl = `https://connect.facebook.net/en_US/sdk.js`;
    const options = { id: 'facebook-jssdk' } as any;
    loadScript(getScriptUrl, options, (error, script) => {
      if (error) {
        console.error('Failed to load facebook sdk');
        return;
      }
      this.isLoaded = true;
    });
  }

  init() {
    this._loadScript();
  }

  event(name: any, options?: { common: any; facebook: any; [key: string]: any }) {
    const { common = {}, facebook: facebookProperties = {} } = options ?? {};
    const eventName = this._mappingEvents[name];

    if (!eventName) {
      // mappingEventName이 빈값인 경우 해당 이벤트를 보내지 않음
      return;
    }

    if (this._wni.isNative) {
      this._wni.execute('wnAnalyticsFacebookSendEvent', {
        eventName: eventName,
        eventProperties: { ...facebookProperties, ...common },
      });
    } else {
      window.FB.AppEvents.logEvent(eventName, facebookProperties);
    }
  }

  setUserId(id: string) {}

  setUserProperty(key: string, value: any) {}

  addUserProperty(key: string, value: number) {}

  clearUser() {}
}
