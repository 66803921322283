import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = ["src", "alt"]
const _hoisted_2 = ["aria-label"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.icon.includes('http'))
    ? (_openBlock(), _createElementBlock("img", {
        key: 0,
        class: _normalizeClass([_ctx.format.page_cover && 'notion-page-icon-offset', $props.big ? 'notion-page-icon-cover' : 'notion-page-icon']),
        src: _ctx.mapImageUrl(_ctx.icon, _ctx.block),
        alt: _ctx.title ? _ctx.getTextContent(_ctx.title) : 'Icon'
      }, null, 10, _hoisted_1))
    : (_ctx.icon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 1,
          role: "img",
          "aria-label": _ctx.icon,
          class: _normalizeClass([
      'notion-emoji',
      _ctx.format.page_cover && 'notion-page-icon-offset',
      $props.big ? 'notion-page-icon-cover' : 'notion-page-icon',
    ])
        }, _toDisplayString(_ctx.icon), 11, _hoisted_2))
      : _createCommentVNode("", true)
}