import download from 'downloadjs';

function base64ToBlob(data, mime) {
  const base64 = window.btoa(window.unescape(encodeURIComponent(data)));
  const bstr = atob(base64);
  let n = bstr.length;
  const u8arr = new Uint8ClampedArray(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

function parseExtraData(extraData, format) {
  let parseData = '';
  if (Array.isArray(extraData)) {
    for (let i = 0; i < extraData.length; i++) {
      parseData += format.replace('${data}', extraData[i]);
    }
  } else {
    parseData += format.replace('${data}', extraData);
  }
  return parseData;
}

function valueReformattedForMultilines(value) {
  if (typeof value == 'string') {
    return value.replace(/\n/gi, '<br/>');
  } else {
    return value;
  }
}

function valueFromHtml(value) {
  return value.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

export function exportFile(data, filename, mime) {
  const blob = base64ToBlob(data, mime);
  download(blob, filename, mime);
}

export function jsonToXLS(data, { title, footer, worksheet }) {
  const styles = [`br { mso-data-placement: same-cell; }`, `td { mso-number-format: "\@"; white-space: pre; }`].join(
    '',
  );
  const xlsTemp =
    '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><meta name=ProgId content=Excel.Sheet> <meta name=Generator content="Microsoft Excel 11"><meta http-equiv="Content-Type" content="text/html; charset=UTF-8"><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>${worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--><style>' +
    styles +
    '</style></head><body><table>${table}</table></body></html>';
  let xlsData = '<thead>';

  if (data.length > 0) {
    const colspan = Object.keys(data[0]).length;

    //Header
    if (title != null) {
      xlsData += parseExtraData(title, '<tr><th colspan="' + colspan + '">${data}</th></tr>');
    }
    //Fields
    xlsData += '<tr>';
    for (const key in data[0]) {
      xlsData += '<th>' + key + '</th>';
    }
    xlsData += '</tr>';
    xlsData += '</thead>';
    //Data
    xlsData += '<tbody>';
    data.map(function (item, index) {
      xlsData += '<tr>';
      for (const key in item) {
        xlsData += '<td>' + valueFromHtml(valueReformattedForMultilines(item[key])) + '</td>';
      }
      xlsData += '</tr>';
    });
    xlsData += '</tbody>';
    //Footer
    if (footer != null) {
      xlsData += '<tfoot>';
      xlsData += parseExtraData(footer, '<tr><td colspan="' + colspan + '">${data}</td></tr>');
      xlsData += '</tfoot>';
    }
  }

  return xlsTemp.replace('${table}', xlsData).replace('${worksheet}', worksheet);
}
