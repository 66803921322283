/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  PassportAuthPhoneVerifyDto,
  PassportAuthPhoneVerifySendDto,
  PassportConnectPhoneDto,
  UserRegisterByDeviceDto,
  UserRegisterByPhoneDto,
} from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class PassportApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags passport-api
   * @name PassportControllerAuthPhoneVerifySend
   * @summary 전화번호 로그인 용 인증 문자 발송
   * @request POST:/v1/passport/auth/phone/verify/send
   */
  passportControllerAuthPhoneVerifySend = (data: PassportAuthPhoneVerifySendDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/passport/auth/phone/verify/send`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags passport-api
   * @name PassportControllerAuthPhoneVerify
   * @summary 인증 문자 인증 확인
   * @request PUT:/v1/passport/auth/phone/verify
   */
  passportControllerAuthPhoneVerify = (data: PassportAuthPhoneVerifyDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/passport/auth/phone/verify`,
      method: 'PUT',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags passport-api
   * @name PassportControllerAuthPhone
   * @summary 인증된 코드로 전화번호 가입 및 로그인
   * @request POST:/v1/passport/auth/phone
   */
  passportControllerAuthPhone = (data: UserRegisterByPhoneDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/passport/auth/phone`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags passport-api
   * @name PassportControllerAuthVerify
   * @summary 세션 체크
   * @request PUT:/v1/passport/auth/verify
   * @secure
   */
  passportControllerAuthVerify = (params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/passport/auth/verify`,
      method: 'PUT',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags passport-api
   * @name PassportControllerCheckByPhone
   * @summary 가입 여부 체크
   * @request GET:/v1/passport/check/phone
   */
  passportControllerCheckByPhone = (
    query: {
      country_dial_code:
        | '+82/KR'
        | '+1/US'
        | '+1/CA'
        | '+886/TW'
        | '+81/JP'
        | '+86/CN'
        | '+65/SG'
        | '+852/HK'
        | '+62/ID'
        | '+60/MY'
        | '+63/PH'
        | '+66/TH'
        | '+673/BN'
        | '+84/VN'
        | '+856/LA'
        | '+95/MM'
        | '+855/KH'
        | '+853/MO'
        | '+880/BD'
        | '+91/IN'
        | '+92/PK'
        | '+966/SA'
        | '+971/AE'
        | '+374/AM'
        | '+994/AZ'
        | '+93/AF'
        | '+355/AL'
        | '+967/AE'
        | '+968/OM'
        | '+962/JO'
        | '+380/UZ'
        | '+972/IL'
        | '+20/EG'
        | '+965/KW'
        | '+996/KG'
        | '+992/TJ'
        | '+90/TR'
        | '+993/TM'
        | '+64/NZ'
        | '+61/AU'
        | '+675/PG'
        | '+30/GR'
        | '+31/NL'
        | '+47/NO'
        | '+45/DK'
        | '+49/DE'
        | '+371/LV'
        | '+7/RU'
        | '+961/LB'
        | '+40/RO'
        | '+352/LU'
        | '+370/LT'
        | '+389/MK'
        | '+356/MT'
        | '+973/BH'
        | '+32/BE'
        | '+387/BY'
        | '+359/BG'
        | '+381/RS'
        | '+46/SE'
        | '+41/CH'
        | '+34/ES'
        | '+421/SK'
        | '+386/SI'
        | '+353/IE'
        | '+372/EE'
        | '+44/GB'
        | '+43/AT'
        | '+39/IT'
        | '+420/CZ'
        | '+385/HR'
        | '+357/CY'
        | '+351/PT'
        | '+48/PL'
        | '+33/FR'
        | '+358/FI'
        | '+36/HU';
      phone: string;
      request_id: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, void>({
      path: `/v1/passport/check/phone`,
      method: 'GET',
      query: query,
      ...params,
    });
  /**
   * No description
   *
   * @tags passport-api
   * @name PassportControllerAuthAnonymous
   * @summary 게스트 로그인
   * @request POST:/v1/passport/auth/anonymous
   */
  passportControllerAuthAnonymous = (data: UserRegisterByDeviceDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/passport/auth/anonymous`,
      method: 'POST',
      body: data,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags passport-api
   * @name PassportConnectControllerCheckConnectPhone
   * @summary 유저 체크
   * @request GET:/v1/passport/connect/auth/phone/check
   * @secure
   */
  passportConnectControllerCheckConnectPhone = (
    query: {
      app_id: number;
      linked_token: string;
      country_dial_code:
        | '+82/KR'
        | '+1/US'
        | '+1/CA'
        | '+886/TW'
        | '+81/JP'
        | '+86/CN'
        | '+65/SG'
        | '+852/HK'
        | '+62/ID'
        | '+60/MY'
        | '+63/PH'
        | '+66/TH'
        | '+673/BN'
        | '+84/VN'
        | '+856/LA'
        | '+95/MM'
        | '+855/KH'
        | '+853/MO'
        | '+880/BD'
        | '+91/IN'
        | '+92/PK'
        | '+966/SA'
        | '+971/AE'
        | '+374/AM'
        | '+994/AZ'
        | '+93/AF'
        | '+355/AL'
        | '+967/AE'
        | '+968/OM'
        | '+962/JO'
        | '+380/UZ'
        | '+972/IL'
        | '+20/EG'
        | '+965/KW'
        | '+996/KG'
        | '+992/TJ'
        | '+90/TR'
        | '+993/TM'
        | '+64/NZ'
        | '+61/AU'
        | '+675/PG'
        | '+30/GR'
        | '+31/NL'
        | '+47/NO'
        | '+45/DK'
        | '+49/DE'
        | '+371/LV'
        | '+7/RU'
        | '+961/LB'
        | '+40/RO'
        | '+352/LU'
        | '+370/LT'
        | '+389/MK'
        | '+356/MT'
        | '+973/BH'
        | '+32/BE'
        | '+387/BY'
        | '+359/BG'
        | '+381/RS'
        | '+46/SE'
        | '+41/CH'
        | '+34/ES'
        | '+421/SK'
        | '+386/SI'
        | '+353/IE'
        | '+372/EE'
        | '+44/GB'
        | '+43/AT'
        | '+39/IT'
        | '+420/CZ'
        | '+385/HR'
        | '+357/CY'
        | '+351/PT'
        | '+48/PL'
        | '+33/FR'
        | '+358/FI'
        | '+36/HU';
      phone: string;
      user_name?: string;
      display_name?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<void, void>({
      path: `/v1/passport/connect/auth/phone/check`,
      method: 'GET',
      query: query,
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags passport-api
   * @name PassportConnectControllerAuthConnectPhone
   * @summary 유저 등록 및 로그인
   * @request POST:/v1/passport/connect/auth/phone
   * @secure
   */
  passportConnectControllerAuthConnectPhone = (data: PassportConnectPhoneDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/passport/connect/auth/phone`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
