<template>
  <div class="notion-row">
    <notion-link
      target="_blank"
      rel="noopener noreferrer"
      :class="['notion-bookmark', f.block_color && `notion-${f.block_color}`]"
      :href="properties.link"
    >
      <div>
        <div class="notion-bookmark-title">
          <NotionTextRenderer :text="title || properties.link" v-bind="pass" />
        </div>
        <div v-if="description" class="notion-bookmark-description">
          <NotionTextRenderer :text="description" v-bind="pass" />
        </div>
        <div class="notion-bookmark-link">
          <img v-if="f.bookmark_icon" :alt="getTextContent(title || properties.link)" :src="f.bookmark_icon" />
          <div>
            <NotionTextRenderer :text="properties.link" v-bind="pass" />
          </div>
        </div>
      </div>
      <div v-if="f.bookmark_cover" class="notion-bookmark-image">
        <img :alt="getTextContent(title || properties.link)" :src="f.bookmark_cover" />
      </div>
    </notion-link>
  </div>
</template>

<script lang="ts">
import NotionLink from './link.vue';
import { Blockable } from '../../composable/blockable';
import NotionTextRenderer from '../helpers/text-renderer.vue';

export default {
  name: 'NotionBookmark',
  components: { NotionTextRenderer, NotionLink },
  extends: Blockable,
};
</script>
