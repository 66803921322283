import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notion-list notion-list-disc"
}
const _hoisted_2 = ["start"]
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotionTextRenderer = _resolveComponent("NotionTextRenderer")!
  const _component_NotionNestedList = _resolveComponent("NotionNestedList")!

  return ($options.isTopLevel && _ctx.type === 'bulleted_list')
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        _createElementVNode("li", null, [
          _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
        ]),
        (_ctx.value.content)
          ? (_openBlock(), _createBlock(_component_NotionNestedList, _normalizeProps(_mergeProps({ key: 0 }, _ctx.pass)), {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            }, 16))
          : _createCommentVNode("", true)
      ]))
    : ($options.isTopLevel && _ctx.type === 'numbered_list')
      ? (_openBlock(), _createElementBlock("ol", {
          key: 1,
          class: "notion-list notion-list-numbered",
          start: $options.start
        }, [
          _createElementVNode("li", null, [
            _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
          ]),
          (_ctx.value.content)
            ? (_openBlock(), _createBlock(_component_NotionNestedList, _normalizeProps(_mergeProps({ key: 0 }, _ctx.pass)), {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
              }, 16))
            : _createCommentVNode("", true)
        ], 8, _hoisted_2))
      : (_openBlock(), _createElementBlock("span", _hoisted_3, [
          _createElementVNode("li", null, [
            _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
          ]),
          (_ctx.value.content)
            ? (_openBlock(), _createBlock(_component_NotionNestedList, _normalizeProps(_mergeProps({ key: 0 }, _ctx.pass)), {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
              }, 16))
            : _createCommentVNode("", true)
        ]))
}