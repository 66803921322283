import { initializeApp } from 'firebase/app';
import { getDatabase, onValue, ref } from 'firebase/database';

interface MessageValue {
  content: string;
  content_Type: string;
  profile_img: string;
  sender_id: number;
  sender_name: string;
  timestamp: any;
}

/**
 * channel 안에 message를 나타냄
 * @class
 */
class Message {
  _manager: ChatManager;
  _channel: Channel;
  _key: string;
  _value: MessageValue;

  /**
   * 메시지의 고유한 식별자를 반환합니다.
   * @returns {string} 메시지 식별자
   */
  get id() {
    return this._key;
  }

  /**
   * 메시지의 값을 반환합니다.
   * @returns {any} 메시지 값
   */
  get value() {
    return this._value ?? {};
  }

  /**
   * 메시지가 수신된 시간을 반환합니다.
   * @returns {string} 메시지 수신 시간 (timestamp)
   */
  get timestamp() {
    return this._value.timestamp ?? '0000000000000000';
  }

  /**
   * 메시지 클래스 인스턴스
   * @param {ChatManager} manager - ChatManager 인스턴스
   * @param {Channel} channel - 메시지가 속한 채널 객체
   * @param {string} key - 메시지 식별자
   * @param {any} value - 메시지 값 객체
   */
  constructor(manager: ChatManager, channel: Channel, key: string, value: any) {
    this._manager = manager;
    this._channel = channel;
    this._key = key;
    this._value = value;
  }

  /**
   * 메시지와 타임스탬프를 비교해서 이 메시지와 같은지 비교
   * @param {string} message - 비교할 메시지 content
   * @param {string} timestamp - 비교할 메시지 timestamp
   * @returns {boolean} - message와 timestamp가 모두 일치하면 true, 아니면 false
   */
  isEqualMessage(message, timestamp) {
    return this._value?.content === message && this._value?.timestamp === timestamp;
  }
}

/**
 * 채팅 채널 클래스
 * @class
 */
export class Channel {
  _manager: ChatManager;
  _group: ChannelGroup;
  _key: string;
  _value: any;
  _channelRef;
  _handler;
  _unsubscriptor;
  _cachedMessages = {};

  /**
   * 채널의 고유한 식별자를 반환
   * @returns {string} 메시지 식별자
   */
  get id() {
    return this._key;
  }

  /**
   * 채널의 값을 반환
   * @returns {any} 채널 값
   */
  get value() {
    return this._value ?? {};
  }

  /**
   * 채널의 정보를 반환
   * @returns {any}
   */
  get info() {
    return this._value?.info || {};
  }

  /**
   * 채널 참여자들을 반환
   * @returns {any}
   */
  get users() {
    return this._value?.users || {};
  }

  get roleBasedUsers() {
    return {
      host: this._value?.host,
      seller: this._value?.seller,
      people: this._value?.people,
    };
  }

  /**
   * 채널에 새로운 메시지가 있는지 여부 반환
   * @returns {boolean} 채널에 새로운 메시지가 없으면 false, 아니면 true
   */
  get isSynced() {
    //@TODO 예외처리가 좀 더 필요해 보임
    return this._value.info.last_message_idx === parseInt(localStorage.getItem(this._key + '.last.message.idx'));
  }

  get space() {
    return this._group._space;
  }

  /**
   * 채널에서 주고 받은 메시지리스트 반환
   * @return {Message[]}
   */
  get messages(): Message[] {
    const items = [];

    for (const key in this._cachedMessages) {
      const item = this._cachedMessages[key];
      items.push(item);
    }

    return items.sort((a: any, b: any) => b.timestamp - a.timestamp);
  }

  get lastMessage() {
    return {
      message: this._value.info.last_message,
      time: this._value.info.last_message_time,
      idx: this._value.info.last_message_idx,
    };
  }

  /**
   * 채널 클래스 인스턴스
   * @param manager - ChatManager 인스턴스
   * @param key - 채널 식별자
   * @param value - 채널 값 객체
   */
  constructor(manager: ChatManager, group: ChannelGroup, key: string, value: any) {
    this._manager = manager;
    this._group = group;
    this._key = key;
    this._value = value;
    this._channelRef = ref(this._manager._realtimeDB, `${this._manager._mode}/channels/${this._key}`);
    this._cachedMessages = [];
  }

  emit(event) {
    if (this._handler) {
      this._handler(this);
    }
  }

  startStreaming(handler) {
    this._handler = handler;

    this._unsubscriptor = onValue(this._channelRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        this.loadData(data);
        this.emit('value');
      }
    });

    this.emit('value');
  }

  stopStreaming() {
    if (this._unsubscriptor) {
      this._unsubscriptor();
      this._unsubscriptor = null;
    }
    this._handler = null;
  }

  loadData(data) {
    // @TODO users 키가 숫자로 되어있어서 값이 배열로 들어오고 있음 처리 필요할지도
    this.loadInfo(data.info);
    this.loadUsers(data.users);
    this.loadMessages(data.messages);
  }

  loadInfo(data) {
    this._value.info = data;
  }

  loadUsers(data) {
    const items = [];

    for (const key in data) {
      const value = data[key];
      items.push(value);
    }

    this._value.users = items;
  }

  loadMessages(data) {
    // console.log('##loadMessages', data);

    for (const key in data) {
      const value = data[key];
      const message = new Message(this._manager, this, key, value);
      this._cachedMessages[message.id] = message;
    }

    this.updateLastMessage();
  }

  updateLastMessage() {
    localStorage.setItem(this._key + '.last.message.idx', this._value?.info.last_message_idx || 0);
    // console.log('##updateLastMessage', this._value?.info.last_message_idx);
  }
}

/**
 * 채팅 그룹 클래스
 * @class
 */
export class ChannelGroup {
  _manager: ChatManager;
  _key: string;
  _value: any;
  _cachedChannels = {};
  _space;

  get value() {
    return this._value ?? {};
  }

  get id() {
    return this._key;
  }

  get space() {
    return this._space;
  }

  get channels(): Channel[] {
    const items = [];

    for (const key in this._cachedChannels) {
      const item = this._cachedChannels[key];
      items.push(item);
    }

    items.sort((a: any, b: any) => b.value.last_message_time - a.value.last_message_time);
    items.sort((a: any, b: any) => (a.isSynced === b.isSynced ? 0 : a.isSynced ? 1 : -1));
    return items;
  }

  get unSyncedCount() {
    let count = 0;
    for (const key in this._cachedChannels) {
      const channel = this._cachedChannels[key];
      if (!channel.isSynced) {
        count += 1;
        break;
      }
    }

    return count;
  }

  constructor(manager, key, item) {
    // console.log('##ChannelGroup constructor', arguments);

    this._manager = manager;
    this._key = key;
    this._value = item;
    this._cachedChannels = {};
  }

  /**
   * manager로 부터 group객체를 위한 데이터 불러오기
   * @param context
   */
  loadData(context) {
    // console.log('##loadData', arguments);

    for (const key in this._value) {
      const value = this._value[key];

      if (!this._space) {
        this.loadSpace(context, value.space);
      }

      this.loadChannel(context, key, value);
    }
  }

  /**
   * 채널그룹 space 정보 불러오기
   * @param context
   * @param space
   */
  loadSpace(context, space) {
    this._space = space;
  }

  /**
   * 그룹에 속한 channel 정보 불러와 channel 객체 생성하고 캐싱하기
   * @param context
   * @param key
   * @param value
   * @returns
   */
  loadChannel(context, key, value) {
    const channel = new Channel(this._manager, this, key, value);
    channel.loadInfo(value);
    context.cachedChannels[channel.id] = channel;
    this._cachedChannels[channel.id] = channel;
    return channel;
  }
}

/**
 * 채팅 매니저 클래스
 * @class
 */
export class ChatManager {
  _firebaseApp;
  _realtimeDB;
  _realtimeDBRef;
  _channelGroupsRef;
  _mode;
  _unsubscriptor;
  _userId;
  _isSynced: boolean;
  _cachedChannels: {};

  // 채널 그룹
  _channelGroups: ChannelGroup[] = [];
  _eventHandlers = {};

  /**
   * config 모드
   * @returns {string} local | staging ...
   */
  get mode() {
    return this._mode;
  }

  get isSynced() {
    return this._isSynced;
  }

  /**
   * 챗 매니저 인스턴스
   */
  constructor() {
    // console.log('##chatMananger constructor', arguments);

    this._userId = null;
    this._channelGroupsRef = null;
    this._cachedChannels = {};
    this._channelGroups = [];
    this._isSynced = true;
  }

  /**
   * 모드를 설정하고 Firebase 앱 인스턴스와 Firebase 실시간 데이터베이스 인스턴스를 초기화
   * @TODO config 보안 강화 필요
   * @param {string} mode - 모드
   */
  init(mode, firebaseConfig) {
    // console.log('##init', arguments);

    this._mode = mode;
    this._firebaseApp = initializeApp(firebaseConfig);
    this._realtimeDB = getDatabase(this._firebaseApp);
    this._realtimeDBRef = ref(this._realtimeDB);
  }

  /**
   * 유저 id를 받아 해당 유저의 db객체 manager에 등록, 해당 유저의 채널 목록 구독
   * @param {string} userId - 로그인된 사용자의 식별id
   */
  async setUser(userId) {
    // console.log('##setUser', arguments);

    this._userId = userId;
    this._channelGroupsRef = ref(this._realtimeDB, `${this._mode}/userSpaceRooms/user-${this._userId}`);
    await this.registerEvents();
  }

  /**
   * Firebase 실시간 데이터베이스에서 유저의 userSpaceRooms 구독해 실시간을 업데이트 되는 ChannelGroups 불러옴
   * value 이벤트 발생
   */
  async registerEvents() {
    // console.log('##registerEvents', arguments);

    return new Promise((resolve, reject) => {
      this._unsubscriptor = onValue(this._channelGroupsRef, (snapshot) => {
        // value이벤트 발생,현재 firebase onVlaue 콜백 안에서 실행되므로 db갱신 될때마다 발생

        const data = snapshot.val();
        // console.log('##registerEventsOnValue', data);
        if (data) {
          this.loadChannelGroups(data);
          this.emit('value');
        }
        resolve(data);
      });
    });
  }

  /**
   * 유저의 ID, 캐시된 채널, 채널 그룹, DB참조 초기화
   */
  clearUser() {
    // console.log('##clearUser', arguments);

    this._userId = null;
    this._channelGroupsRef = null;
    this._cachedChannels = {};
    this._channelGroups = [];

    this.unregisterEvents();
  }

  /**
   * DB참조 onValue 이벤트 수신대기 해제
   */
  unregisterEvents() {
    // console.log('##unregisterEvents', arguments);

    if (this._unsubscriptor) {
      this._unsubscriptor();
      this._unsubscriptor = null;
    }
  }

  /**
   * emits이 실행되면 이벤트에 맞는 _eventHandlers에 찾아 실행
   * @param event - 이벤트 이름
   */
  emit(event) {
    // console.log('##emit', arguments);

    for (const key in this._eventHandlers) {
      const handlers = this._eventHandlers[key];
      if (handlers[event]) {
        const handler = handlers[event];
        handler();
      }
    }
  }

  /**
   * value 이벤트 핸들러 등록
   * @param key -
   * @param handler - value 발생시 실행될 콜백 함수 (value 이벤트 핸들러)
   */
  onValue(key, handler) {
    // console.log('##onValue', arguments);

    if (!this._eventHandlers[key]) {
      this._eventHandlers[key] = {};
    }

    this._eventHandlers[key].value = handler;

    setTimeout(() => {
      this.emit('value');
    }, 0);
  }

  /**
   * 이벤트 핸들러 초기화
   * @param key -
   */
  clearEvents(key) {
    // console.log('##clearEvents', arguments);

    if (this._eventHandlers[key]) {
      this._eventHandlers[key] = null;
      delete this._eventHandlers[key];
    }
  }

  /**
   * userSpaceRooms/user-userId 객체 배열 값을 받아 각 space별 로 ChannelGroup 인스턴스 생성 및 channelGroup에 추가
   * @param dataSets - snapshot data
   * @returns
   */
  loadChannelGroups(dataSets) {
    // console.log('##loadChannelGroups', arguments);

    const items = [];

    for (const key in dataSets) {
      const dataSet = dataSets[key];
      const item = new ChannelGroup(this, key, dataSet);

      item.loadData({
        cachedChannels: this._cachedChannels,
      });
      items.push(item);
    }

    this._channelGroups = items;
    return items;
  }

  getChannel(channelId): Channel | null {
    // console.log('##getChannel', arguments);

    if (!this._cachedChannels[channelId]) {
      throw new Error('NOT_FOUND_CHANNEL');
    }

    return this._cachedChannels[channelId];
  }

  getChannelGroups() {
    // console.log('##getChannelGroup', this._channelGroups);

    this._channelGroups.sort((groupA, groupB) => {
      const channelA = this.channelsSort(groupA._cachedChannels)[0];
      const channelB = this.channelsSort(groupB._cachedChannels)[0];

      if (channelA.isSynced === channelB.isSynced) {
        return channelB._value.last_message_time - channelA._value.last_message_time;
      } else {
        return channelA.isSynced ? 1 : -1;
      }
    });

    return this._channelGroups;
  }

  channelsSort(group) {
    const cachedChannels = [];
    for (const key in group) {
      const channel = group[key];
      cachedChannels.push(channel);
    }

    cachedChannels.sort((a: any, b: any) => b.value.last_message_time - a.value.last_message_time);
    cachedChannels.sort((a: any, b: any) => (a.isSynced === b.isSynced ? 0 : a.isSynced ? 1 : -1));

    return cachedChannels;
  }

  checkSynced() {
    let flag = true;
    for (const key in this._cachedChannels) {
      const channel = this._cachedChannels[key];
      if (!channel.isSynced) {
        flag = false;
        break;
      }
    }

    return flag;
  }
}
