import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 2,
  class: "notion-image-caption"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotionImage = _resolveComponent("NotionImage")!
  const _component_NotionAsset = _resolveComponent("NotionAsset")!
  const _component_NotionTextRenderer = _resolveComponent("NotionTextRenderer")!

  return (_openBlock(), _createElementBlock("figure", {
    class: "notion-asset-wrapper",
    style: _normalizeStyle(_ctx.width)
  }, [
    (_ctx.isType('image'))
      ? (_openBlock(), _createBlock(_component_NotionImage, _normalizeProps(_mergeProps({ key: 0 }, _ctx.pass)), null, 16))
      : (_ctx.isType(['embed', 'video', 'figma']))
        ? (_openBlock(), _createBlock(_component_NotionAsset, _normalizeProps(_mergeProps({ key: 1 }, _ctx.pass)), null, 16))
        : _createCommentVNode("", true),
    (_ctx.caption)
      ? (_openBlock(), _createElementBlock("figcaption", _hoisted_1, [
          _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.caption }, _ctx.pass), null, 16, ["text"])
        ]))
      : _createCommentVNode("", true)
  ], 4))
}