import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notion-toggle"
}
const _hoisted_2 = {
  key: 0,
  class: "notion-h1"
}
const _hoisted_3 = {
  key: 1,
  class: "notion-h2"
}
const _hoisted_4 = {
  key: 2,
  class: "notion-h3"
}
const _hoisted_5 = {
  key: 0,
  class: "notion-h1"
}
const _hoisted_6 = {
  key: 1,
  class: "notion-h2"
}
const _hoisted_7 = {
  key: 2,
  class: "notion-h3"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotionTextRenderer = _resolveComponent("NotionTextRenderer")!

  return (_ctx.format && _ctx.format.toggleable === true)
    ? (_openBlock(), _createElementBlock("details", _hoisted_1, [
        (_ctx.type === 'header')
          ? (_openBlock(), _createElementBlock("summary", _hoisted_2, [
              _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.type === 'sub_header')
          ? (_openBlock(), _createElementBlock("summary", _hoisted_3, [
              _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.type === 'sub_sub_header')
          ? (_openBlock(), _createElementBlock("summary", _hoisted_4, [
              _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "default")
        ])
      ]))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.type === 'header')
          ? (_openBlock(), _createElementBlock("h1", _hoisted_5, [
              _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
            ]))
          : (_ctx.type === 'sub_header')
            ? (_openBlock(), _createElementBlock("h2", _hoisted_6, [
                _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
              ]))
            : (_ctx.type === 'sub_sub_header')
              ? (_openBlock(), _createElementBlock("h3", _hoisted_7, [
                  _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
                ]))
              : _createCommentVNode("", true)
      ], 64))
}