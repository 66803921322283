<template>
  <div v-if="level === 0 && fullPage" class="notion">
    <!-- todo: add header -->
    <!-- <NotionPageHeader v-if="!hideHeader" v-bind="pass" /> -->
    <!-- todo: hide image if no .format is available -->
    <img
      v-if="format && format.page_cover"
      class="notion-page-cover"
      :style="coverStyle"
      :alt="getTextContent(title)"
      :src="mapImageUrl(format.page_cover, block)"
    />
    <main
      :class="[
        'notion-page',
        format && !format.page_cover && 'notion-page-offset',
        format && format.page_full_width && 'notion-full-width',
        format && format.page_small_text && 'notion-small-text',
      ]"
    >
      <NotionPageIcon v-bind="pass" big />
      <div class="notion-title">
        <NotionTextRenderer :text="title" v-bind="pass" />
      </div>
      <slot></slot>
    </main>
  </div>
  <main v-else-if="level === 0" class="notion"><slot></slot></main>
  <component
    v-bind="pageLinkProps(value.id)"
    :is="pageLinkOptions.component"
    v-else-if="hasPageLinkOptions"
    class="notion-page-link"
  >
    <div class="notion-page-icon">
      <NotionPageIcon v-bind="pass" />
    </div>
    <div class="notion-page-text">
      <NotionTextRenderer :text="title" v-bind="pass" />
    </div>
  </component>
  <notion-link v-else class="notion-page-link" :target="pageLinkTarget" :href="mapPageUrl(value.id)">
    <div class="notion-page-icon">
      <NotionPageIcon v-bind="pass" />
    </div>
    <div class="notion-page-text">
      <NotionTextRenderer :text="title" v-bind="pass" />
    </div>
  </notion-link>
</template>

<script lang="ts">
import NotionLink from './link.vue';
import { Blockable, blockComputed } from '../../composable/blockable';
import NotionPageHeader from '../helpers/page-header.vue';
import NotionPageIcon from '../helpers/page-icon.vue';
import NotionTextRenderer from '../helpers/text-renderer.vue';

export default {
  name: 'NotionPage',
  components: { NotionPageHeader, NotionPageIcon, NotionTextRenderer, NotionLink },
  extends: Blockable,
  computed: {
    ...blockComputed,
    coverStyle() {
      const coverPosition = (1 - (this.format.page_cover_position || 0.5)) * 100;
      return { objectPosition: `center ${coverPosition}%` };
    },
  },
};
</script>
