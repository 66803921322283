import config from '@/config';
import { ChatManager } from './libs/chat.manager';

let __sharedManager__;

export function useChatModule(): ChatManager {
  return __sharedManager__;
}

const plugin = {
  install: (app, options = {}) => {
    const manager = new ChatManager();
    const mode = config.mode === 'debug' ? 'local' : config.mode;

    manager.init(mode, config.get('firebase'));

    __sharedManager__ = manager;
  },
};

export default plugin;
