import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["target"]
const _hoisted_2 = { key: 6 }
const _hoisted_3 = {
  key: 8,
  class: "notion-inline-code"
}
const _hoisted_4 = { key: 9 }
const _hoisted_5 = { key: 10 }
const _hoisted_6 = { key: 11 }
const _hoisted_7 = {
  key: 13,
  class: "notion-inline-code"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_notion_link = _resolveComponent("notion-link")!
  const _component_NotionDecorator = _resolveComponent("NotionDecorator")!

  return ($options.isPageLink && _ctx.hasPageLinkOptions)
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.pageLinkOptions.component), _mergeProps({ key: 0 }, _ctx.pageLinkProps($options.decoratorValue), { class: "notion-link" }), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString($options.pageLinkTitle), 1)
        ]),
        _: 1
      }, 16))
    : ($options.isPageLink)
      ? (_openBlock(), _createBlock(_component_notion_link, _mergeProps({
          key: 1,
          class: "notion-link"
        }, _ctx.pass, {
          target: _ctx.pageLinkTarget,
          href: _ctx.mapPageUrl($options.decoratorValue)
        }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString($options.pageLinkTitle), 1)
          ]),
          _: 1
        }, 16, ["target", "href"]))
      : ($options.decoratorKey === 'a' && _ctx.hasPageLinkOptions && $options.isInlinePageLink)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.pageLinkOptions.component), _mergeProps({ key: 2 }, _ctx.pageLinkProps($options.decoratorValue.slice(1)), { class: "notion-link" }), {
            default: _withCtx(() => [
              _createVNode(_component_NotionDecorator, _mergeProps({ content: $options.nextContent }, _ctx.pass), null, 16, ["content"])
            ]),
            _: 1
          }, 16))
        : ($options.decoratorKey === 'a' && $options.isInlinePageLink)
          ? (_openBlock(), _createBlock(_component_notion_link, _mergeProps({
              key: 3,
              class: "notion-link"
            }, _ctx.pass, {
              target: $options.target,
              href: _ctx.mapPageUrl($options.decoratorValue.slice(1))
            }), {
              default: _withCtx(() => [
                _createVNode(_component_NotionDecorator, _mergeProps({ content: $options.nextContent }, _ctx.pass), null, 16, ["content"])
              ]),
              _: 1
            }, 16, ["target", "href"]))
          : ($options.decoratorKey === 'a')
            ? (_openBlock(), _createBlock(_component_notion_link, _mergeProps({
                key: 4,
                class: "notion-link"
              }, _ctx.pass, {
                target: $options.target,
                href: $options.decoratorValue
              }), {
                default: _withCtx(() => [
                  _createVNode(_component_NotionDecorator, _mergeProps({ content: $options.nextContent }, _ctx.pass), null, 16, ["content"])
                ]),
                _: 1
              }, 16, ["target", "href"]))
            : ($options.decoratorKey === 'button')
              ? (_openBlock(), _createElementBlock("button", {
                  key: 5,
                  class: "notion-link",
                  target: $options.target,
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => ($options.onClickButton && $options.onClickButton(...args)))
                }, [
                  _createVNode(_component_NotionDecorator, _mergeProps({ content: $options.nextContent }, _ctx.pass), null, 16, ["content"])
                ], 8, _hoisted_1))
              : ($options.decorators.length === 0)
                ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString($options.text), 1))
                : ($options.decoratorKey === 'h')
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 7,
                      class: _normalizeClass('notion-' + $options.decoratorValue)
                    }, [
                      _createVNode(_component_NotionDecorator, _mergeProps({ content: $options.nextContent }, _ctx.pass), null, 16, ["content"])
                    ], 2))
                  : ($options.decoratorKey === 'c')
                    ? (_openBlock(), _createElementBlock("code", _hoisted_3, [
                        _createVNode(_component_NotionDecorator, _mergeProps({ content: $options.nextContent }, _ctx.pass), null, 16, ["content"])
                      ]))
                    : ($options.decoratorKey === 'b')
                      ? (_openBlock(), _createElementBlock("b", _hoisted_4, [
                          _createVNode(_component_NotionDecorator, _mergeProps({ content: $options.nextContent }, _ctx.pass), null, 16, ["content"])
                        ]))
                      : ($options.decoratorKey === 'i')
                        ? (_openBlock(), _createElementBlock("em", _hoisted_5, [
                            _createVNode(_component_NotionDecorator, _mergeProps({ content: $options.nextContent }, _ctx.pass), null, 16, ["content"])
                          ]))
                        : ($options.decoratorKey === 's')
                          ? (_openBlock(), _createElementBlock("s", _hoisted_6, [
                              _createVNode(_component_NotionDecorator, _mergeProps({ content: $options.nextContent }, _ctx.pass), null, 16, ["content"])
                            ]))
                          : ($options.decoratorKey === 'e' && _ctx.katex)
                            ? (_openBlock(), _createBlock(_resolveDynamicComponent('katex-element'), {
                                key: 12,
                                expression: $options.decoratorValue
                              }, null, 8, ["expression"]))
                            : ($options.decoratorKey === 'e')
                              ? (_openBlock(), _createElementBlock("code", _hoisted_7, _toDisplayString($options.decoratorValue), 1))
                              : (_openBlock(), _createBlock(_component_NotionDecorator, _mergeProps({
                                  key: 14,
                                  content: $options.nextContent
                                }, _ctx.pass), null, 16, ["content"]))
}