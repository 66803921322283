import '@/assets/scss/styles.scss';
import './config';
import './serviceWorker';

import { createApp } from 'vue';
import App from './App.vue';
import plugins from './plugins';
import router from './router';
import store from './stores';

createApp(App).use(store).use(router).use(plugins).mount('#app');
