import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "notion-row" }
const _hoisted_2 = { class: "notion-bookmark-title" }
const _hoisted_3 = {
  key: 0,
  class: "notion-bookmark-description"
}
const _hoisted_4 = { class: "notion-bookmark-link" }
const _hoisted_5 = ["alt", "src"]
const _hoisted_6 = {
  key: 0,
  class: "notion-bookmark-image"
}
const _hoisted_7 = ["alt", "src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotionTextRenderer = _resolveComponent("NotionTextRenderer")!
  const _component_notion_link = _resolveComponent("notion-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_notion_link, {
      target: "_blank",
      rel: "noopener noreferrer",
      class: _normalizeClass(['notion-bookmark', _ctx.f.block_color && `notion-${_ctx.f.block_color}`]),
      href: _ctx.properties.link
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_NotionTextRenderer, _mergeProps({
              text: _ctx.title || _ctx.properties.link
            }, _ctx.pass), null, 16, ["text"])
          ]),
          (_ctx.description)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.description }, _ctx.pass), null, 16, ["text"])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_4, [
            (_ctx.f.bookmark_icon)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  alt: _ctx.getTextContent(_ctx.title || _ctx.properties.link),
                  src: _ctx.f.bookmark_icon
                }, null, 8, _hoisted_5))
              : _createCommentVNode("", true),
            _createElementVNode("div", null, [
              _createVNode(_component_NotionTextRenderer, _mergeProps({
                text: _ctx.properties.link
              }, _ctx.pass), null, 16, ["text"])
            ])
          ])
        ]),
        (_ctx.f.bookmark_cover)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("img", {
                alt: _ctx.getTextContent(_ctx.title || _ctx.properties.link),
                src: _ctx.f.bookmark_cover
              }, null, 8, _hoisted_7)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["class", "href"])
  ]))
}