import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 1,
  class: "notion-blank"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotionTextRenderer = _resolveComponent("NotionTextRenderer")!

  return (_ctx.properties)
    ? (_openBlock(), _createElementBlock("p", {
        key: 0,
        class: _normalizeClass(['notion-text', _ctx.blockColorClass()])
      }, [
        _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
      ], 2))
    : (_openBlock(), _createElementBlock("div", _hoisted_1, " "))
}