<template>
  <div class="page-content">
    <p class="emoji">😶‍🌫️</p>
    <div class="title">
      <p>{{ $t('이 버전은 수명을 다했어요') }}</p>
    </div>

    <div class="message">
      <p>{{ $t('{version} 으로 앱 업데이트가 필요해요', { version }) }}</p>
    </div>
    <button type="button" class="btn-filled btn-medium" @click="onClickUpdate">
      <span>{{ $t('업데이트 하러 가기') }}</span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { $t } from '@/plugins/locale';
import { useWNInterface } from '@/plugins/vue-wni';
import { useAppStore } from '@/stores/modules/app';
import logger from '@/utils/logger';

const debug = logger('app:components:resources:launch:LaunchNeedToUpdate');
const app = useAppStore();

const version = computed(() => app.initData.app.app_version_minimum);
const url = computed(() => app.initData.update_link);
const updateStatus = computed(() => app.initData.update_status);

function onClickUpdate(e) {
  if (useWNInterface().isNative) {
    useWNInterface().execute('wnOpenURL', {
      url: url.value,
      exit: true,
    });
  } else {
    window.open(url.value);
  }
}
function onClickSkip(e) {}
</script>

<style lang="scss" scoped>
.page-content {
  @include column-flexbox(start);
  padding-top: 40%;

  .title {
    @include text-variant(headline2);
    margin-top: unit(42);
  }
  .emoji {
    font-size: unit(120);
    text-align: center;
  }

  .slogan {
    margin-top: unit(16);
    width: 100%;

    p {
      text-align: center;
      font-size: unit(18);
      font-weight: 600;
    }
  }

  .message {
    @include text-variant(body2, $color-gray-120);
    margin-top: unit(6);
    text-align: center;
    word-wrap: break-word;
    word-break: keep-all;

    &:deep() {
      em {
        font-weight: 700;
      }
    }
  }
}

button {
  margin-top: unit(32);
}
</style>
