import { resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = {
  key: 11,
  class: "notion-row"
}
const _hoisted_3 = {
  key: 17,
  class: "notion-sync-block"
}
const _hoisted_4 = {
  key: 19,
  class: "notion-hr"
}
const _hoisted_5 = { key: 20 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotionPage = _resolveComponent("NotionPage")!
  const _component_NotionToggle = _resolveComponent("NotionToggle")!
  const _component_NotionHeader = _resolveComponent("NotionHeader")!
  const _component_NotionBookmark = _resolveComponent("NotionBookmark")!
  const _component_NotionCallout = _resolveComponent("NotionCallout")!
  const _component_NotionCode = _resolveComponent("NotionCode")!
  const _component_NotionEquation = _resolveComponent("NotionEquation")!
  const _component_NotionText = _resolveComponent("NotionText")!
  const _component_NotionQuote = _resolveComponent("NotionQuote")!
  const _component_NotionTodo = _resolveComponent("NotionTodo")!
  const _component_NotionColumn = _resolveComponent("NotionColumn")!
  const _component_NotionList = _resolveComponent("NotionList")!
  const _component_NotionFigure = _resolveComponent("NotionFigure")!
  const _component_NotionTable = _resolveComponent("NotionTable")!
  const _component_NotionSyncPointer = _resolveComponent("NotionSyncPointer")!
  const _component_NotionTableRow = _resolveComponent("NotionTableRow")!

  return (_ctx.blockOverrides.hasOwnProperty(_ctx.type))
    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.blockOverrides[_ctx.type]), _normalizeProps(_mergeProps({ key: 0 }, _ctx.pass)), null, 16))
    : (_ctx.isType('page'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_NotionPage, _normalizeProps(_guardReactiveProps(_ctx.pass)), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 16)
        ]))
      : (_ctx.isType('toggle'))
        ? (_openBlock(), _createBlock(_component_NotionToggle, _normalizeProps(_mergeProps({ key: 2 }, _ctx.pass)), {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 3
          }, 16))
        : (_ctx.isType(['header', 'sub_header', 'sub_sub_header']))
          ? (_openBlock(), _createBlock(_component_NotionHeader, _normalizeProps(_mergeProps({ key: 3 }, _ctx.pass)), {
              default: _withCtx(() => [
                _renderSlot(_ctx.$slots, "default")
              ]),
              _: 3
            }, 16))
          : (_ctx.isType('bookmark'))
            ? (_openBlock(), _createBlock(_component_NotionBookmark, _normalizeProps(_mergeProps({ key: 4 }, _ctx.pass)), null, 16))
            : (_ctx.isType('callout'))
              ? (_openBlock(), _createBlock(_component_NotionCallout, _normalizeProps(_mergeProps({ key: 5 }, _ctx.pass)), null, 16))
              : (_ctx.isType('code'))
                ? (_openBlock(), _createBlock(_component_NotionCode, _normalizeProps(_mergeProps({ key: 6 }, _ctx.pass)), null, 16))
                : (_ctx.isType('equation'))
                  ? (_openBlock(), _createBlock(_component_NotionEquation, _normalizeProps(_mergeProps({ key: 7 }, _ctx.pass)), null, 16))
                  : (_ctx.isType('text'))
                    ? (_openBlock(), _createBlock(_component_NotionText, _normalizeProps(_mergeProps({ key: 8 }, _ctx.pass)), null, 16))
                    : (_ctx.isType('quote'))
                      ? (_openBlock(), _createBlock(_component_NotionQuote, _normalizeProps(_mergeProps({ key: 9 }, _ctx.pass)), null, 16))
                      : (_ctx.isType('to_do'))
                        ? (_openBlock(), _createBlock(_component_NotionTodo, _normalizeProps(_mergeProps({ key: 10 }, _ctx.pass)), null, 16))
                        : (_ctx.isType('column_list'))
                          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                              _renderSlot(_ctx.$slots, "default")
                            ]))
                          : (_ctx.isType('column'))
                            ? (_openBlock(), _createBlock(_component_NotionColumn, {
                                key: 12,
                                format: _ctx.format
                              }, {
                                default: _withCtx(() => [
                                  _renderSlot(_ctx.$slots, "default")
                                ]),
                                _: 3
                              }, 8, ["format"]))
                            : (_ctx.isType(['bulleted_list', 'numbered_list']))
                              ? (_openBlock(), _createBlock(_component_NotionList, _normalizeProps(_mergeProps({ key: 13 }, _ctx.pass)), {
                                  default: _withCtx(() => [
                                    _renderSlot(_ctx.$slots, "default")
                                  ]),
                                  _: 3
                                }, 16))
                              : (_ctx.isType(['image', 'embed', 'figma', 'video', 'audio']))
                                ? (_openBlock(), _createBlock(_component_NotionFigure, _normalizeProps(_mergeProps({ key: 14 }, _ctx.pass)), null, 16))
                                : (_ctx.isType('table'))
                                  ? (_openBlock(), _createBlock(_component_NotionTable, _normalizeProps(_mergeProps({ key: 15 }, _ctx.pass)), {
                                      default: _withCtx(() => [
                                        _renderSlot(_ctx.$slots, "default")
                                      ]),
                                      _: 3
                                    }, 16))
                                  : ($options.isRendererRegistered && _ctx.isType('transclusion_reference'))
                                    ? (_openBlock(), _createBlock(_component_NotionSyncPointer, _normalizeProps(_mergeProps({ key: 16 }, _ctx.pass)), null, 16))
                                    : ($options.isRendererRegistered && _ctx.isType('transclusion_container'))
                                      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                          _renderSlot(_ctx.$slots, "default")
                                        ]))
                                      : (_ctx.isType('table_row'))
                                        ? (_openBlock(), _createBlock(_component_NotionTableRow, _normalizeProps(_mergeProps({ key: 18 }, _ctx.pass)), null, 16))
                                        : (_ctx.isType('divider'))
                                          ? (_openBlock(), _createElementBlock("hr", _hoisted_4))
                                          : (_ctx.todo && _ctx.visible)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                                                _createTextVNode(" todo: " + _toDisplayString(_ctx.type) + " ", 1),
                                                _renderSlot(_ctx.$slots, "default")
                                              ]))
                                            : _createCommentVNode("", true)
}