<template>
  <div class="page error">
    <main class="page-body">
      <div class="center-content">
        <div class="error-icon">
          <img src="/imgs/emoji/emoji-wow.png" />
        </div>
        <div class="message">
          <h1>{{ $t(title) }}</h1>
          <p>{{ $t(discript) }}</p>
        </div>
        <button type="button" class="btn-filled btn-medium" @click="onClickRetry">
          {{ $t('다시 시도하기') }}
        </button>
      </div>
    </main>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { $t } from '@/plugins/locale';

const props = defineProps({
  error: {
    type: Object,
    default: () => ({}),
  },
});

const isNetworkError = computed(() => props.error.message === 'Network Error');
const title = computed(() => (isNetworkError.value ? '네트워크 오류' : '알 수 없는 오류'));
const discript = computed(() => {
  if (isNetworkError.value) {
    return '네트워크 연결이 원활하지 않습니다.\n잠시후 다시 시도해 주세요';
  }
  return '알 수 없는 오류가 발생했습니다.\n잠시후 다시 시도해 주세요';
});

function onClickRetry(e) {
  location.reload();
}
</script>

<style lang="scss" scoped>
.error {
  height: 100%;

  .page-body {
    @include flexbox();
  }
}

.center-content {
  margin-bottom: 50%;
  text-align: center;

  .error-icon {
    margin-bottom: unit(-24);
    font-size: unit(160);

    img {
      width: 1em;
      height: 1em;
    }
  }

  h1 {
    @include text-variant(headline2);
    margin-top: unit(42);
  }

  p {
    @include text-variant('body1', $color-gray-120);
    margin-top: unit(6);
    white-space: pre-line;
  }
}

button {
  margin-top: unit(32);
}
</style>
