<template>
  <figure class="notion-asset-wrapper" :style="width">
    <NotionImage v-if="isType('image')" v-bind="pass" />
    <NotionAsset v-else-if="isType(['embed', 'video', 'figma'])" v-bind="pass" />
    <figcaption v-if="caption" class="notion-image-caption">
      <NotionTextRenderer :text="caption" v-bind="pass" />
    </figcaption>
  </figure>
</template>

<script lang="ts">
import { Blockable } from '../../composable/blockable';
import NotionAsset from '../helpers/asset.vue';
import NotionImage from '../helpers/image.vue';
import NotionTextRenderer from '../helpers/text-renderer.vue';

export default {
  name: 'NotionFigure',
  components: {
    NotionAsset,
    NotionImage,
    NotionTextRenderer,
  },
  extends: Blockable,
};
</script>
