<template>
  <teleport to="#teleport-area">
    <div v-if="items.length > 0" @click="closeDrawer" class="drawer-bg"></div>
    <div class="drawer-container">
      <transition-group tag="ul" :name="state.transitionName">
        <drawer-item v-for="item in items" :ref="item.ref" :key="item.id" :payload="item" />
      </transition-group>
    </div>
  </teleport>
</template>

<script lang="ts" setup>
import { computed, onMounted, reactive, watch } from 'vue';
import { useDrawerStore } from '@/stores/modules/drawer';
import DrawerItem from './DrawerItem.vue';

const props = defineProps({
  observerHandler: {
    type: Function,
    default: null,
  },
});

const drawerModule = useDrawerStore();

onMounted(() => {
  if (props.observerHandler) {
    drawerModule.addObserver(props.observerHandler);
  }
});

watch(
  () => drawerModule.transitionName,
  (newValue, oldValue) => {
    state.transitionName = newValue;
  },
);

const items = computed(() => drawerModule.items);
const state = reactive({
  transitionName: drawerModule.transitionName,
});

function closeDrawer() {
  drawerModule.dismissAll();
}
</script>

<style lang="scss" scoped>
.drawer-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: calc($toast-index - 10);
}
.drawer-container {
  position: relative;
  display: block;

  z-index: calc($toast-index - 10);

  .slide-up-enter-active,
  .slide-up-leave-active {
    //easeInOutQuart
    /* transition: all 350ms cubic-bezier(0.76, 0, 0.24, 1); */

    //easeInOutQuad
    transition: transform 250ms cubic-bezier(0.45, 0, 0.55, 1);
  }
  .slide-up-enter-from,
  .slide-up-leave-to {
    /* opacity: 0; */
    transform: translateY(100%);
  }
}
</style>
