<template>
  <div v-if="katex">
    <component :is="'katex-element'" :expression="equation" />
  </div>
  <NotionCode v-else v-bind="pass" override-lang="latex" override-lang-class="language-latex" />
</template>

<script lang="ts">
import NotionCode from './code.vue';
import { Blockable, blockComputed } from '../../composable/blockable';

export default {
  name: 'NotionEquation',
  components: { NotionCode },
  extends: Blockable,
  computed: {
    ...blockComputed,
    equation() {
      return this.properties?.title?.[0]?.[0];
    },
  },
};
</script>
