import { defineStore } from 'pinia';
import { SpaceApi } from '@/api/SpaceApi';
import { createLogger } from '@/utils/logger';

const debug = createLogger('app:stores:space');

interface SpaceState {
  cached: { [key: string]: any };
}

export const useSpaceStore = defineStore({
  id: 'space',
  state: (): SpaceState => ({
    cached: {},
  }),
  getters: {
    getCached: (state) => (key: string) => {
      return state.cached[key];
    },
  },
  actions: {
    setCached(id: string, data: any) {
      const key = id?.toString();

      if (data === null) {
        this.cached[key] = null;
        delete this.cached[key];
        return;
      }

      if (this.cached[key]) {
        for (const column in data) {
          this.cached[key][column] = data[column];
        }
      } else {
        this.cached[key] = data;
      }
    },
    clearCachedItem(id: string) {
      const key = id;
      this.cached[key] = null;
      delete this.cached[key];
    },
    async fetchSpace(id: number) {
      const api = new SpaceApi();
      const { data } = await api.spaceControllerFindOne(id);
      this.setCached(id.toString(), data);
      return data;
    },
    async createSpace(space: any) {
      const api = new SpaceApi();
      const { data } = await api.spaceControllerCreate(space);
      return data;
    },
    async updateSpace(id: number, space: any) {
      const api = new SpaceApi();
      const { data } = await api.spaceControllerUpdate(id, space);
      return data;
    },
    async deleteSpace(id: number) {
      const api = new SpaceApi();
      const { data } = await api.spaceControllerRemove(id);
      return data;
    },
  },
});
