import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["alt"]
const _hoisted_2 = ["alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.f.block_aspect_ratio)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        style: _normalizeStyle($options.style)
      }, [
        ($options.hasImageComponent)
          ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.imageOptions.component), _mergeProps({
              key: 0,
              class: "notion-image-inset",
              alt: _ctx.alt || 'Notion image'
            }, $options.imageProps), null, 16, ["alt"]))
          : (_openBlock(), _createElementBlock("img", _mergeProps({
              key: 1,
              class: "notion-image-inset",
              alt: _ctx.alt || 'Notion image'
            }, $options.imageProps), null, 16, _hoisted_1))
      ], 4))
    : ($options.hasImageComponent)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.imageOptions.component), _mergeProps({
          key: 1,
          alt: _ctx.alt || 'Notion image'
        }, $options.imageProps), null, 16, ["alt"]))
      : (_openBlock(), _createElementBlock("img", _mergeProps({
          key: 2,
          alt: _ctx.alt
        }, $options.imageProps), null, 16, _hoisted_2))
}