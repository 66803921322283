<template>
  <div :class="['page', route.name, showKeyboard]" :style="state.styles">
    <space-register-banner
      :progress="state.progress"
      v-if="state.showSpaceRegisterBanner"
      @close="sessionStore.setIsSpaceRegisterBannerClose(true)"
    />
    <router-view v-slot="{ Component }" :key="$route.fullPath">
      <!-- <keep-alive> -->
      <component :is="Component" />
      <!-- </keep-alive> -->
    </router-view>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive, watch } from 'vue';
import { useRoute } from 'vue-router';
import SpaceRegisterBanner from '@/components/resources/space/SpaceRegisterBanner.vue';
import { useAnalytics } from '@/plugins/analytics';
import { useWNInterface } from '@/plugins/vue-wni';
import { useDrawerStore } from '@/stores/modules/drawer';
import { useLayoutStore } from '@/stores/modules/layout';
import { useSessionStore } from '@/stores/modules/session';
import { useSpaceStore } from '@/stores/modules/space';

const route = useRoute();
const drawerModule = useDrawerStore();
const layoutStore = useLayoutStore();
const spaceStore = useSpaceStore();
const wni = useWNInterface();
const analytics = useAnalytics();
const sessionStore = useSessionStore();
const showKeyboard = computed(() => (state.keyboard_height > 0 ? 'keyboard-show' : ''));

const draftSpace = computed(() => spaceStore.getCached('draft_space'));
const state = reactive({
  isDrawer: computed(() => !drawerModule.items.length),
  keyboard_height: computed(() => layoutStore.keyboardHeight),
  styles: computed(() => {
    if (!wni.isAndroid) {
      return;
    }

    if (state.keyboard_height > 0 && state.isDrawer) {
      return { '--keyboard-height': `${state.keyboard_height}px` };
    }
    return {};
  }),
  isSpaceRegisterBannerClose: computed(() => sessionStore.isSpaceRegisterBannerClose),
  showSpaceRegisterBanner: computed(() => {
    if (draftSpace.value && (route.name === 'Home' || route.name === 'Mypage') && !state.isSpaceRegisterBannerClose) {
      analytics.event('view_register_space_continue_banner');
      return true;
    }

    return false;
  }),
});
</script>
<style lang="scss" scoped>
.page {
  height: 100%;
  box-sizing: content-box;

  &::after {
    content: '';
    display: block;
    height: var(--keyboard-height);
  }
}
</style>
