<template>
  <div class="resource-content page-content">
    <div class="title">
      <p v-html="$t('<em>{displayName}</em>님은<br/>해당 서비스 사용이 중지되었어요', { displayName })"></p>
    </div>

    <div class="message">
      <ul>
        <li>
          <p>{{ $t('해당 서비스는 이용자가 정책을 준수하지 않은 경우 사용 중지됩니다.') }}</p>
        </li>
        <li>
          <p>{{ $t('해당 계정 복원 요청을 위해서는 1:1 문의하기를 이용해 주세요') }}</p>
        </li>
      </ul>
    </div>
  </div>
  <page-actions>
    <button type="button" class="btn-large btn-filled" @click="onClickContact">
      <span>{{ $t('1:1 문의하기') }}</span>
    </button>
  </page-actions>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import PageActions from '@/components/commons/layout/PageActions.vue';
import { $t } from '@/plugins/locale';

const displayName = ref('아무개아무개아');

function onClickContact(e) {}
</script>

<style lang="scss" scoped>
.page-content {
  @include column-flexbox(start);
  padding-top: 55%;

  .logo {
    position: relative;
    width: 70%;
    padding-top: 25%;

    img {
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }

  .title {
    @include text-variant(headline1);
    text-align: center;
    margin-top: unit(32);
  }

  .slogan {
    margin-top: unit(16);
    width: 100%;
    height: unit(26);

    p {
      text-align: center;
      font-size: unit(18);
      font-weight: 600;
    }
  }

  .message {
    @include text-variant(subhead1);
    margin-top: unit(16);
    text-align: center;
    word-wrap: break-word;
    word-break: keep-all;

    &:deep() {
      em {
        font-weight: 700;
      }
    }
  }
}
</style>
