import VueTheMask from 'vue-the-mask';
import VueResize from 'vue3-resize';
import config from '@/config';
import VueLocale, { useLocaleModule } from '@/plugins/locale';
import VueMap from '@/plugins/vue-map';
import VueNoitonRenderer from '@/plugins/vue-notion-renderer';
import VueWNInterface, { useWNInterface } from '@/plugins/vue-wni';
import VueAnalytics from './analytics';
import VueChat from './chat';
import VueSwiper from './swiper';

import '@/assets/icons';
import 'vue3-resize/dist/vue3-resize.css';

// require('default-passive-events');

// for Emulator
if (useWNInterface().isEmulator) {
  require('@/plugins/wni-simulator');

  // eslint-disable-next-line dot-notation
  window['VirtualInterface']['device'] = {
    appId: config.get('appId'),
    appName: config.get('appName'),
    appVersion: `0.0.0`,
    brand: '',
    carrier: '',
    deviceId: '229df161194d0f4b6385e61895022688d6',
    deviceName: `WNInterface Sample iPhone`,
    deviceLocale: 'ko-KR',
    deviceModel: 'iPhone XS',
    deviceType: 'mobile',
    deviceVersion: '',
    manufacturer: '',
    osType: 'ios',
    osVersion: '13.3.1',
    userAgent:
      'Mozilla/5.0 (iPhone; CPU iPhone OS 13_3_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 WNEmulator WNInterface/v1',
    isTablet: false,
  };
}

const plugin = {
  install: (app: any, options: any) => {
    useLocaleModule().loadLanguagePack({});

    if (config.mode !== 'production') {
      useLocaleModule().useHistory(true);
    }

    app.use(VueResize);
    app.use(VueLocale);
    app.use(VueSwiper);
    app.use(VueTheMask);
    app.use(VueChat);
    app.use(VueMap);
    app.use(VueWNInterface);
    app.use(VueAnalytics);
    app.use(VueNoitonRenderer);
  },
};

export default plugin;
