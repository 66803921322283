import { createLogger } from '@/utils/logger';

const debug = createLogger(`mobidic:plugin:wninterface.simulator`);
debug.enabled = false;

class VirtualInterface {
  async _call(funcName, info = {}) {
    debug('_call', funcName, info);
    try {
      const context = eval(funcName.substr(0, funcName.length - 8)) || window;
      const handler = eval(funcName);
      await handler.call(context, JSON.stringify(info));
    } catch (e) {
      console.error(e);
      debug(e);
    }
  }

  async _exception(data) {
    debug('exception', data);

    const { callback } = data;
    if (callback) {
      const info = { status: 'NOT_SUPPORTED' };
      await this._call(callback, info);
    }
  }

  constructor() {
    this._methods = {
      wnLog: this.wnLog.bind(this),

      // Web
      wnWebContentLoaded: this.wnWebContentLoaded.bind(this),
      wnWebHistoryClear: this.wnWebHistoryClear.bind(this),
      wnWebMoveURL: this.wnWebMoveURL.bind(this),

      // App
      wnAppInfo: this.wnAppInfo.bind(this),
      wnAppExtension: this.wnAppExtension.bind(this),
      wnAppShare: this.wnAppShare.bind(this),
      wnAppChangeStatusBar: this.wnAppChangeStatusBar.bind(this),
      wnAppGetSafeArea: this.wnAppGetSafeArea.bind(this),

      // Device
      wnDeviceRotationMask: this.wnDeviceRotationMask.bind(this),

      // Auth
      wnAuthLogin: this.wnAuthLogin.bind(this),
      wnAuthLogout: this.wnAuthLogout.bind(this),

      // Permission
      wnPermissionVideoAccess: this.wnPermissionVideoAccess.bind(this),
      wnPermissionLocation: this.wnPermissionLocation.bind(this),
      wnPermission: this.wnPermission.bind(this),

      // Media
      wnMediaPhotoGet: this.wnMediaPhotoGet.bind(this),
      wnMediaPhotoUpload: this.wnMediaPhotoUpload.bind(this),

      // Scanner
      wnScannerOpen: this.wnScannerOpen.bind(this),
      wnScannerClose: this.wnScannerClose.bind(this),

      // Data
      wnDataStorageGetAll: this.wnDataStorageGetAll.bind(this),
      wnDataStorageGet: this.wnDataStorageGet.bind(this),
      wnDataStorageSet: this.wnDataStorageSet.bind(this),

      // Toast
      wnToastShow: this.wnToastShow.bind(this),

      // Vibrate
      wnVibrate: this.wnVibrate.bind(this),

      // Location
      wnLocationCurrent: this.wnLocationCurrent.bind(this),
      wnLocationWatch: this.wnLocationWatch.bind(this),

      // Push
      wnPushRegister: this.wnPushRegister.bind(this),
      wnPushBadgeNumber: this.wnPushBadgeNumber.bind(this),

      // Popup
      wnPopupOpen: this.wnPopupOpen.bind(this),
      wnPopupClose: this.wnPopupClose.bind(this),

      // Window
      wnWindowOpen: this.wnWindowOpen.bind(this),
      wnWindowClose: this.wnWindowClose.bind(this),

      // Send
      wnSendReview: this.wnSendReview.bind(this),
      wnSendEmail: this.wnSendEmail.bind(this),

      // Open
      wnOpenURL: this.wnOpenURL.bind(this),
      wnOpenAppSetting: this.wnOpenAppSetting.bind(this),
    };
  }

  postMessage(message) {
    // debug('postMessage', message);

    const json = JSON.parse(message);
    const { version, payload } = json;

    if (version === 'v1') {
      const { command, data } = payload;
      // debug('WNInterface.onMessage', command, data);

      const handler = this._methods[command];

      if (handler != null) {
        handler.apply(handler, [data]);
      } else {
        // ignore: avoid_print
        console.log(`Unknown Command : ${payload.command}`);
      }
    }
  }

  async onReady(payload = {}) {
    debug('WNInterface.onReady');

    await window.WNInterface.responder.onInitPage(JSON.stringify(payload));
    // await this._call(`WNInterface && WNInterface.responder.onInitPage`, payload)
  }

  async onAppear(payload = {}) {
    debug('WNInterface.onAppear');

    await window.WNInterface.responder.onResumePage(JSON.stringify(payload));
    // await this._call(`WNInterface && WNInterface.responder.onResumePage`, payload)
  }

  async onDisappear(payload = {}) {
    debug('WNInterface.onDisappear');

    await window.WNInterface.responder.onPausePage(JSON.stringify(payload));
    // await this._call(`WNInterface && WNInterface.responder.onPausePage`, payload)
  }

  wnMoveURL(payload) {
    debug('wnMoveURL', payload);
    console.log('wnMoveURL is deprecated !! Use wnWebMoveURL instead');
    this.wnWebMoveURL(payload);
  }

  wnLog(payload) {
    debug('wnLog', payload);
  }

  wnWebContentLoaded(payload = {}) {
    debug('wnWebContentLoaded', payload);

    if (!this._timeout) {
      clearTimeout(this._timeout);
      this._timeout = null;
    }

    this._timeout = setTimeout(() => {
      if (this.__WEB_CONTENT_LOADED__) {
        console.warn('Already Web Content Loaded...');
        return;
      }

      this.__WEB_CONTENT_LOADED__ = true;

      this.onReady({
        interfaceVersion: 'v1',
        token: '',
      });
    }, 100);
  }

  wnWebHistoryClear(payload = {}) {
    debug('wnWebHistoryClear', payload);
    console.warn('wnWebHistoryClear is deprecated !!');
    console.warn('wnWebHistoryClear is not working !!');
  }

  wnWebMoveURL(payload = {}) {
    debug('wnWebMoveURL', payload);

    const { url } = payload;
    location.href = url;
  }

  wnAppInfo(payload) {
    debug('wnAppInfo', payload);

    const { callback } = payload;
    const info = window.VirtualInterface.device;

    this._call(callback, info);
  }

  wnAppExtension(payload) {
    debug('wnAppExtension', payload);

    this._exception(payload);
  }

  wnAppShare(payload) {
    debug('wnAppShare', payload);

    this._exception(payload);
  }

  wnAppChangeStatusBar(payload) {
    debug('wnAppChangeStatusBar', payload);

    this._exception(payload);
  }

  wnAppGetSafeArea(payload) {
    debug('wnAppGetSafeArea', payload);

    this._exception(payload);
  }

  wnDeviceRotationMask(payload) {
    debug('wnDeviceRotationMask', payload);

    this._exception(payload);
  }

  wnAuthLogin(payload) {
    debug('wnAuthLogin', payload);

    this._exception(payload);
  }

  wnAuthLogout(payload) {
    debug('wnAuthLogout', payload);

    this._exception(payload);
  }

  wnPermissionVideoAccess(payload) {
    debug('wnPermissionVideoAccess', payload);

    this._exception(payload);
  }

  async wnPermissionLocation(payload) {
    debug('wnPermissionLocation', payload);

    const callback = payload['callback'];

    navigator.permissions
      .query({ name: 'geolocation' })
      .then((result) => {
        callback({
          status: result.state.toUpperCase(),
        });
      })
      .catch((e) => {
        callback({
          status: 'ERROR',
          message: e.message,
        });
      });
  }

  wnPermission(payload) {
    debug('wnPermission', payload);

    this._exception(payload);
  }

  wnMediaPhotoGet(payload) {
    debug('wnMediaPhotoGet', payload);

    this._exception(payload);
  }

  wnMediaPhotoUpload(payload) {
    debug('wnMediaPhotoUpload', payload);

    this._exception(payload);
  }

  wnScannerOpen(payload) {
    debug('wnScannerOpen', payload);

    this._exception(payload);
  }

  wnScannerClose(payload) {
    debug('wnScannerClose', payload);

    this._exception(payload);
  }

  wnDataStorageGetAll(payload) {
    debug('wnDataStorageGetAll', payload);

    const { keys, callback } = payload;

    const values = {};
    keys.forEach((key) => {
      const value = localStorage.getItem(key);
      values[key] = value;
    });

    if (callback) {
      this._call(callback, { status: 'SUCCESS', values });
    }
  }

  wnDataStorageGet(payload) {
    debug('wnDataStorageGet', payload);

    const { key, callback } = payload;
    const value = localStorage.getItem(key);

    if (callback) {
      this._call(callback, { status: 'SUCCESS', key, value });
    }
  }

  wnDataStorageSet(payload) {
    debug('wnDataStorageSet', payload);

    const { key, value, callback } = payload;
    localStorage.setItem(key, value);

    if (callback) {
      this._call(callback, { status: 'SUCCESS', key, value });
    }
  }

  wnToastShow(payload) {
    debug('wnToastShow', payload);

    this._exception(payload);
  }

  wnVibrate(payload) {
    debug('wnVibrate', payload);

    this._exception(payload);
  }

  wnLocationCurrent(payload) {
    debug('wnLocationCurrent', payload);

    const callback = payload['callback'];

    if (typeof navigator?.geolocation?.getCurrentPosition !== 'function') {
      callback({ status: 'ERROR' });
      return;
    }

    const success = (result) => {
      if (result?.coords) {
        callback({ status: 'ERROR' });
        return;
      }

      const { latitude, longitude } = result?.coords;
      callback({ status: 'SUCCESS', latitude, longitude });
    };

    const error = (error) => {
      callback({ status: 'ERROR', message: error.message });
    };

    watchId = navigator.geolocation.getCurrentPosition(success, error);
  }

  wnLocationWatch(payload) {
    debug('wnLocationWatch', payload);

    const callback = payload['callback'];

    if (typeof navigator?.geolocation?.watchPosition !== 'function') {
      callback({ status: 'ERROR' });
      return;
    }

    let watchId;

    const success = (result) => {
      if (result?.coords) {
        callback({ status: 'ERROR' });
        return;
      }

      const { latitude, longitude } = result?.coords;
      callback({ status: 'SUCCESS', watch_id: watchId, latitude, longitude });
    };

    const error = (error) => {
      callback({ status: 'ERROR', message: error.message });
    };

    watchId = navigator.geolocation.watchPosition(success, error);
  }

  wnLocationWatchClear(payload) {
    debug('wnLocationWatchClear', payload);

    try {
      const watchId = payload['watch_id'];
      const callback = payload['callback'];

      if (typeof navigator?.geolocation?.clearWatch !== 'function') {
        callback({ status: 'ERROR' });
        return;
      }

      navigator.geolocation.clearWatch(parseInt(watchId));
      callback({ status: 'SUECCESS' });
    } catch (e) {
      callback({ status: 'ERROR', message: e.message });
    }
  }

  wnPushRegister(payload) {
    debug('wnPushRegister', payload);

    this._exception(payload);
  }

  wnPushBadgeNumber(payload) {
    debug('wnPushBadgeNumber', payload);

    this._exception(payload);
  }

  wnPopupOpen(payload) {
    debug('wnPopupOpen', payload);

    this._exception(payload);
  }

  wnPopupClose(payload) {
    debug('wnPopupClose', payload);

    this._exception(payload);
  }

  wnWindowOpen(payload) {
    debug('wnWindowOpen', payload);

    this._exception(payload);
  }

  wnWindowClose(payload) {
    debug('wnWindowClose', payload);

    this._exception(payload);
  }

  wnSendReview(payload) {
    debug('wnSendReview', payload);

    this._exception(payload);
  }

  wnSendEmail(payload) {
    debug('wnSendEmail', payload);

    this._exception(payload);
  }

  wnOpenURL(payload) {
    debug('wnOpenURL', payload);

    this._exception(payload);
  }

  wnOpenURL(payload) {
    debug('wnOpenURL', payload);

    const { url } = payload;
    window.open(url);
  }

  wnOpenAppSetting(payload) {
    debug('wnOpenAppSetting', payload);
  }
}

window.VirtualInterface = new VirtualInterface();
