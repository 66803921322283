<template>
  <span>
    <NotionDecorator v-for="(t, i) in text" :key="i" :content="t" v-bind="pass" />
  </span>
</template>

<script lang="ts">
import { Blockable, blockProps } from '../../composable/blockable';
import NotionDecorator from '../blocks/decorator.vue';

export default {
  name: 'NotionTextRenderer',
  components: {
    NotionDecorator,
  },
  extends: Blockable,
  props: { ...blockProps, text: Array },
};
</script>
