import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value", "checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotionTextRenderer = _resolveComponent("NotionTextRenderer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("input", {
      type: "checkbox",
      value: _ctx.title,
      checked: _ctx.properties.checked
    }, null, 8, _hoisted_1),
    _createElementVNode("label", null, [
      _createVNode(_component_NotionTextRenderer, _mergeProps({ text: _ctx.title }, _ctx.pass), null, 16, ["text"])
    ])
  ]))
}