import mustache from 'mustache';
import { defineStore } from 'pinia';
import { App } from 'vue';
import { exportFile, jsonToXLS } from './lib/export';
import {
  Language,
  MappingCountryLanguages,
  MappingDocumentLanguages,
  MappingGlobalLanguages,
  MappingLanguages,
} from './types';

class TranslateHistory {
  // key와 value를 private 멤버로 변경
  private _key: string;
  private _value: { [key in Language]?: string };

  // get 메소드를 사용해 private 멤버에 대한 접근 제어
  get key(): string {
    return this._key;
  }

  get value(): { [key in Language]?: string } {
    return this._value;
  }

  constructor(key: string, value: { [key in Language]?: string } = {}) {
    this._key = key;
    this._value = value;
  }

  log(value: string, language: Language): void {
    this._value[language] = value;
  }

  toString() {
    return JSON.stringify({
      key: this._key,
      value: this._value,
    });
  }

  static parse(string: string): TranslateHistory {
    const { key, value } = JSON.parse(string);
    return new TranslateHistory(key, value);
  }
}

class TranslateHistoryLogger {
  private static readonly LANGUAGE_HISTORY_KEY: string = '__LANGUAGE_HISTORY_KEY__';

  // private 멤버로 선언된 _historyItems에 대한 접근을 막기 위해 getHistory() 메소드를 삭제
  // 필요한 경우 public 메소드를 추가하여 안전한 방법으로 _historyItems에 접근할 수 있도록 함

  public historyItems: { [key: string]: TranslateHistory } = {};

  log(key: string, value: string, language: Language): void {
    let history = this.historyItems[key];
    if (!history) {
      history = new TranslateHistory(key);
      this.historyItems[key] = history;
    }
    history.log(value, language);

    const historyStr = JSON.stringify(this.historyItems);

    // 저장된 데이터 크기를 체크하고, 저장 크기를 초과하는 경우 오래된 데이터부터 삭제
    // if (historyStr.length > localStorage.length - 1024) {
    //   this._cleanupHistory();
    // }

    // @MEMO: TranslateHistoryLogger 는 웹에서만 사용 가능
    localStorage.setItem(TranslateHistoryLogger.LANGUAGE_HISTORY_KEY, historyStr);
  }

  private _cleanupHistory() {
    const keys = Object.keys(this.historyItems);
    const sortedKeys = keys.sort((a, b) => {
      const historyA = this.historyItems[a];
      const historyB = this.historyItems[b];
      if (!historyA || !historyB) {
        return 0;
      }

      const aTimestamp = new Date(historyA.key).getTime();
      const bTimestamp = new Date(historyB.key).getTime();
      return aTimestamp - bTimestamp;
    });

    let historyStr = '';
    for (const key of sortedKeys) {
      const history = this.historyItems[key];
      if (history) {
        const newHistoryStr = JSON.stringify({ [key]: history });
        if (historyStr.length + newHistoryStr.length <= localStorage.length - 1024) {
          historyStr += newHistoryStr;
          delete this.historyItems[key];
        } else {
          break;
        }
      }
    }

    localStorage.setItem(TranslateHistoryLogger.LANGUAGE_HISTORY_KEY, historyStr);
  }

  loadHistory(): void {
    const historyStr = localStorage.getItem(TranslateHistoryLogger.LANGUAGE_HISTORY_KEY);
    if (historyStr) {
      const historyItems: { [key: string]: TranslateHistory } = {};
      try {
        // localStorage에 저장된 데이터를 JSON.parse() 메소드를 사용해 파싱하고, 예외 처리
        const historyObj = JSON.parse(historyStr);

        for (const key in historyObj) {
          const historyValue = historyObj[key];
          if (historyValue) {
            // historyItems[key] = TranslateHistory.parse(historyValue);
            historyItems[key] = new TranslateHistory(key, historyValue._value);
          }
        }

        this.historyItems = historyItems;
      } catch (err) {
        console.error('Failed to parse history data from local storage', err);
        // localStorage.removeItem(TranslateHistoryLogger.LANGUAGE_HISTORY_KEY);
      }
    }
  }
}

class LanguageModule {
  private static instance;
  private static readonly LANGUAGE_KEY: string = '__LANGUAGE_KEY__';

  private _languagePack: any;
  private _logger: TranslateHistoryLogger;
  private _systemLanguage: Language;
  private _currentLanguage: Language;
  private _currentCountryCode: string;
  private _enabledLogger: boolean = false;

  get currentLanguage(): Language {
    return this._getLanguageKey();
  }

  get currentCountryCode(): string {
    return this._currentCountryCode ?? '';
  }

  get logger(): TranslateHistoryLogger {
    return this._logger;
  }

  _detectLocale() {
    const navigator = window.navigator as any;
    const userLanguage =
      navigator.language ||
      (navigator.languages && navigator.languages.length > 0 ? navigator.languages[0] : '') ||
      navigator.userLanguage ||
      navigator.systemLanguage;
    const zhHans = ['zh', 'zh-Hans', 'zh-CN'];
    const zhHant = ['zh-Hant', 'zh-TW', 'zh-HK', 'zh-MO'];

    // trunk-ignore(eslint/prefer-const)
    let [language, country] = userLanguage.split('-');

    if (zhHans.includes(language)) {
      language = Language.SIMPLIFIED_CHINESE;
    } else if (zhHant.includes(language)) {
      language = Language.TRADITIONAL_CHINESE;
    }
    // console.log('lang : ', language);

    const languageKey = localStorage.getItem(LanguageModule.LANGUAGE_KEY) ?? '';
    // console.log('languageKey : ', languageKey);

    this._systemLanguage = language || Language.SYSTEM;
    this._currentLanguage = MappingLanguages[languageKey] ?? Language.SYSTEM;
    this._currentCountryCode = country ?? MappingCountryLanguages[this._systemLanguage!];

    // console.log('1 :', this._systemLanguage);
    // console.log('2 :', this._currentLanguage);
    // console.log('3 :', this._currentCountryCode);
  }

  _setDocumentLanguage(language: Language) {
    const html = window.document.querySelector('html');
    if (html) {
      // BCP 47 언어 태그
      html.setAttribute('lang', MappingDocumentLanguages[language]);
    }
  }

  _getLanguageKey() {
    let langKey: Language = this._currentLanguage ?? Language.SYSTEM;

    if (langKey === Language.SYSTEM) {
      langKey = this._systemLanguage;
    }

    return langKey;
  }

  _getTremplate(key: string) {
    const language: Language = this._getLanguageKey();

    if (language === Language.KOREAN) {
      return this._languagePack[key]?.ko ?? key;
    }

    return this._languagePack[key]?.[language] ?? this._languagePack[key]?.en ?? key;
  }

  constructor() {
    this._logger = new TranslateHistoryLogger();
    this._logger.loadHistory();

    this._detectLocale();

    // @MEMO: 강제 테스트
    // this.setLanguage(Language.JAPANESE);

    setTimeout(() => {
      this._setDocumentLanguage(this.currentLanguage);
    }, 0);
  }

  useHistory(enabled: boolean) {
    this._enabledLogger = enabled;

    if (this._enabledLogger) {
      for (const key in this._languagePack) {
        const pack = this._languagePack[key];
        for (const language in pack) {
          this._logger.log(key, this._languagePack[key]?.[language], language as Language);
        }
      }
    }
  }

  loadLanguagePack(languagePack: any) {
    this._languagePack = languagePack;
  }

  setLanguage(language: Language) {
    // console.log('setLanguage : ', language);
    console.trace();

    localStorage.setItem(LanguageModule.LANGUAGE_KEY, language);
    this._currentLanguage = language;

    this._setDocumentLanguage(language);
  }

  translate(key: string, options: any = null) {
    const template = this._getTremplate(key);

    if (this._enabledLogger) {
      const language: Language = this._getLanguageKey();
      this._logger.log(key, this._languagePack[key]?.[language], language);
    }

    if (options) {
      return mustache.render(template.replace(/{/g, '{{').replace(/}/g, '}}'), options);
    }

    return template;
  }

  static getInstance(): LanguageModule {
    if (!LanguageModule.instance) {
      LanguageModule.instance = new LanguageModule();
    }
    return LanguageModule.instance;
  }
}

// Load Language Pack
// LanguageModule.getInstance().loadLanguagePack(languagePack);

export function useLocaleModule() {
  return LanguageModule.getInstance() as LanguageModule;
}

export function exportTranslateHistory(isJson = false) {
  const json = [];
  const historyItems = LanguageModule.getInstance().logger.historyItems;

  for (const key in historyItems) {
    const history = historyItems[key];
    json.push({
      key,
      'ko': history.value.ko || key,
      'en': history.value.en || '',
      'ja': history.value.ja || '',
      'zh-Hans': history.value['zh-Hans'] || '',
      'zh-Hant': history.value['zh-Hant'] || '',
    });
  }

  if (isJson) {
    return exportFile(JSON.stringify(json, null, 2), 'translate.json', 'text/json');
  }

  return exportFile(
    jsonToXLS(json, { title: '', footer: '', worksheet: 'sheet1' }),
    'translate.xls',
    'application/vnd.ms-excel',
  );
}

window['exportTranslateHistory'] = exportTranslateHistory;

// Store
interface LocaleState {
  timezone: string;
}

export const useLocaleStore = defineStore({
  id: 'locale',
  state: (): LocaleState => ({
    timezone: 'Asia/Seoul',
  }),
  getters: {
    currentLanguage() {
      return LanguageModule.getInstance().currentLanguage;
    },
    languageItems() {
      const items = [];
      for (const key in MappingGlobalLanguages) {
        items.push({
          label: MappingGlobalLanguages[key],
          value: key,
        });
      }
      return items;
    },
  },
  actions: {
    setLanguage(language: Language) {
      LanguageModule.getInstance().setLanguage(language);
    },

    setTimezone(timezone: string) {
      this.timezone = timezone;
    },
  },
});

// Short Function
export function $t(key: string, options: any = null) {
  return LanguageModule.getInstance().translate.apply(LanguageModule.getInstance(), arguments);
}

// plugin
export default {
  install: (app: App): void => {
    app.provide('$t', LanguageModule.getInstance().translate.bind(LanguageModule.getInstance()));
    app.config.globalProperties.$t = LanguageModule.getInstance().translate.bind(LanguageModule.getInstance());
  },
};
