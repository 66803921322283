import _ from 'lodash';
import { defineStore } from 'pinia';
import { PassportApi } from '@/api/PassportApi';
import { TermsAgreeApi } from '@/api/TermsAgreeApi';
import { UserApi } from '@/api/UserApi';
import config from '@/config';
import { useAnalytics } from '@/plugins/analytics';
import { sdkAxios, setAccessToken, setSDKAccessToken } from '@/plugins/axios';
import { useChatModule } from '@/plugins/chat';
import { parseJSON } from '@/utils';
import { createLogger } from '@/utils/logger';
import { StorageModule } from '@/utils/storage';
import { zkp } from '@/utils/zero-knowledge-proof';
import { useAppStore } from './app';

const debug = createLogger('mobidic:stores:session');

const storage = new StorageModule();

const __SESSION_TOKEN__ = '__SESSION_TOKEN__';
const __SESSION_PROFILE__ = '__SESSION_PROFILE__';
const __SESSION_SDK_TOKEN__ = '__SESSION_SDK_TOKEN__';

interface SessionState {
  profile: any;
  token: string;
  sdkToken: string;
  sdkProfile: any;
  terms: any;
  redirectGuideSpaceRegister: boolean;
  isSpaceRegisterBannerClose: boolean;
}

async function setAnalyticsUserProperties() {
  try {
    const analytics = useAnalytics();
    const api = new UserApi();
    const res = await api.userControllerGetUserInfo();
    const { status, data } = res as any;

    const {
      total_add_like,
      signup_year,
      signup_month,
      signup_week,
      signup_date,
      last_login_date,
      total_register_space,
      total_request_space,
      total_book_space,
      user_type,
      user_code,
      auth_type,
    } = data;

    if (auth_type === 'device') {
      return;
    }

    analytics.setUserId(user_code);

    analytics.setUserProperties({
      total_add_like,
      signup_year,
      signup_month,
      signup_week,
      signup_date,
      last_login_date,
      total_register_space,
      total_request_space,
      total_book_space,
      user_type,
    });

    analytics.addUserProperty('total_login_count', 1);
  } catch (error) {
    console.error(error);
  }
}

export const useSessionStore = defineStore({
  id: 'session',
  state: (): SessionState => ({
    profile: null,
    token: '',
    sdkProfile: null,
    sdkToken: '',
    terms: null,
    redirectGuideSpaceRegister: false,
    isSpaceRegisterBannerClose: false,
  }),
  getters: {
    isLogged: (state) => {
      return state.token && state.profile && state.profile.role_level > 0 ? true : false;
    },
    accessToken: (state) => {
      return state.token;
    },
  },
  actions: {
    init() {
      debug('init');

      return new Promise(async (resolve, reject) => {
        try {
          let token = await storage.get(__SESSION_TOKEN__);
          let sdkToken = await storage.get(__SESSION_SDK_TOKEN__);
          const profile = await storage.get(__SESSION_PROFILE__);

          if (token === 'null') {
            token = '';
          }

          if (sdkToken === 'null') {
            sdkToken = '';
          }

          this.token = (token || '') as string;
          this.sdkToken = (sdkToken || '') as string;

          this.profile = parseJSON(profile || '{}');

          setAccessToken(this.token);
          setSDKAccessToken(this.sdkToken);

          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    anonymous(payload) {
      debug('anonymous', payload);

      return new Promise(async (resolve, reject) => {
        try {
          const app = useAppStore();
          const zkpCandidates = app.zkpCandidates;
          const zkpBits = app.zkpBits;

          const candidate_index = _.random(0, zkpCandidates.length - 1);
          const candidate = zkpCandidates[candidate_index];

          const { publicKey } = zkp.generateRandomKeys(zkpBits);
          const [zkp_cipher, zkp_proof] = zkp.encryptWithProof(publicKey, candidate, zkpCandidates, zkpBits);

          const deviceInfo = app.deviceInfo;
          const api = new PassportApi();
          const res = await api.passportControllerAuthAnonymous({
            device_id: deviceInfo?.device_id ?? '',
            public_key: zkp.encodePublicKey(publicKey),
            candidates: zkpCandidates,
            cipher: zkp.encodeCipher(zkp_cipher),
            proof: zkp.encodeProof(zkp_proof),
          });

          const { status, message, data } = res as any;

          if (status === 'ERROR') {
            throw new Error(message || 'Unknown Error');
          }

          await this.register(data);

          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    passportByPhone(payload) {
      debug('passportByPhone', payload);

      return new Promise(async (resolve, reject) => {
        try {
          const app = useAppStore();
          const api = new PassportApi();
          const res = await api.passportControllerAuthPhone({
            phone: payload.to,
            display_name: payload.name,
            request_id: payload.request_id,
            country_dial_code: '+82/KR',
          });

          const { status, message, data } = res as any;

          if (status === 'ERROR') {
            throw new Error(message || 'Unknown Error');
          }
          await this.register(data);
          await this.authorizeSDK();

          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    verify(payload) {
      debug('verify', payload);

      return new Promise(async (resolve, reject) => {
        try {
          const api = new PassportApi();
          const res = await api.passportControllerAuthVerify();

          const { status, message, data } = res as any;

          if (status === 'ERROR') {
            throw new Error(message || 'Unknown Error');
          }

          await this.register(data);

          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    register(payload) {
      debug('register', payload);

      return new Promise(async (resolve, reject) => {
        try {
          const { profile, token } = payload;

          await storage.set(__SESSION_TOKEN__, token);
          await storage.set(__SESSION_PROFILE__, JSON.stringify(profile));

          this.profile = profile;
          this.token = token;

          await useChatModule().setUser(profile.gid);
          await setAccessToken(this.token);
          await setSDKAccessToken(this.sdkToken);

          setAnalyticsUserProperties();

          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    unregister() {
      debug('unregister');

      return new Promise(async (resolve, reject) => {
        try {
          await storage.set(__SESSION_TOKEN__, '');
          await storage.set(__SESSION_PROFILE__, '{}');

          this.profile = null;
          this.token = '';

          await this.unregisterSDK();

          useChatModule().clearUser();
          useAnalytics().clearUser();

          setAccessToken(this.token);
          setSDKAccessToken(this.sdkToken);

          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    // ------------- SDK ---------------
    authorizeSDK() {
      debug('authorizeSDK');

      return new Promise(async (resolve, reject) => {
        try {
          if (!this.accessToken) {
            throw new Error('세션을 찾을 수 없어요');
          }
          await this.unregisterSDK();

          let res: any = await sdkAxios.post(`/v1/oauth/authorize`, {
            app_key: config.connect.app_key,
            scopes: 'basic',
            auth_gateway: 'uhoo',
            auth_type: 'token',
            auth_data: JSON.stringify({ token: this.accessToken }),
            token: this.accessToken,
            profile: JSON.stringify(this.profile),
          });

          if (!!res.data.data.code) {
            res = await sdkAxios.post(`/v1/oauth/token`, {
              app_key: config.connect.app_key,
              grant_type: 'authorization_code',
              code: res.data.data.code,
            });
          }

          if (!res.data.data.access_token) {
            throw new Error('INVALID_TOKEN');
          }

          const { access_token } = res.data.data;
          setSDKAccessToken(access_token);

          const resProfile = await sdkAxios.get('/v1/oauth/user/me');
          const profile = resProfile.data.data;
          await this.registerSDK({
            profile,
            token: access_token,
          });

          resolve({ access_token, profile });
        } catch (e) {
          reject(e);
        }
      });
    },
    verifySDK() {
      debug('verifySDK');

      return new Promise(async (resolve, reject) => {
        try {
          setSDKAccessToken(this.sdkToken);
          const resProfile = await sdkAxios.get('/v1/oauth/user/me');
          const profile = resProfile.data.data;
          this.sdkProfile = profile;
          resolve({ access_token: this.sdkToken, profile });
        } catch (e) {
          reject(e);
        }
      });
    },
    setAccessTokenSDK(token) {
      debug('setAccessTokenSDK', token);

      return new Promise(async (resolve, reject) => {
        try {
          setSDKAccessToken(token);
          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    registerSDK(payload: { profile: any; token: any }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { profile, token } = payload;

          await storage.set(__SESSION_SDK_TOKEN__, token || '');

          this.sdkProfile = profile;
          this.sdkToken = token;

          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    unregisterSDK() {
      return new Promise(async (resolve, reject) => {
        try {
          await storage.set(__SESSION_SDK_TOKEN__, '');

          this.sdkProfile = [];
          this.sdkToken = '';

          resolve(this);
        } catch (e) {
          reject(e);
        }
      });
    },
    // ------------- //SDK ---------------
    async updateProfile(payload) {
      debug('profileUpdate', payload);

      this.profile = {
        ...this.profile,
        ...payload,
      };

      await storage.set(__SESSION_PROFILE__, JSON.stringify(this.profile));
    },
    checkAgreeToTerms() {
      debug('agreeToTerms', arguments);
      return new Promise(async (resolve, reject) => {
        try {
          const api = new TermsAgreeApi();
          const res = await api.termsAgreeControllerCheck();

          const { status, message, data } = res as any;

          if (status === 'ERROR') {
            throw new Error(message || 'Unknown Error');
          }

          resolve(data);
        } catch (e) {
          reject(e);
        }
      });
    },
    setRedirectGuideSpaceRegister(value: boolean) {
      this.redirectGuideSpaceRegister = value;
    },
    getRedirectGuideSpaceRegister() {
      return this.redirectGuideSpaceRegister;
    },
    setIsSpaceRegisterBannerClose(value: boolean) {
      this.isSpaceRegisterBannerClose = value;
    },
  },
});
