<template>
  <div class="page-content">
    <div class="logo">
      <img src="/imgs/common/logo.png" alt="UHOO" />
    </div>
    <div class="slogan">
      <p>{{ $t('유휴 공간의 변신') }}</p>
    </div>
    <div class="loader" v-if="state.loading">
      <ui-loader type="wave" :size="1.8" />
      <p v-if="message">
        {{ $t(message) }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, reactive } from 'vue';
import UiLoader from '@/components/commons/loader/Loader.vue';
import { $t } from '@/plugins/locale';
import { createLogger } from '@/utils/logger';

const debug = createLogger('app:components:resources:launch:LaunchLoading');
const state = reactive({
  _timeout: null,
  loading: false,
  messages: [
    '공간 정보를 불러오는 중이에요~',
    '매칭 정보를 불러오는 중이에요~',
    '호스트를 불러오는 중이에요~',
    '에이전트를 모집하는 중이에요~',
    '당신의 공간의 가치를 높여드려요~',
  ],
  messageIndex: -1,
});
const message = computed(() => {
  if (state.messageIndex >= 0) {
    return state.messages[state.messageIndex];
  }

  return '';
});

function startToShowRandomMessages({ delay = 500 }) {
  state.messages.sort(() => (Math.random() > 0.5 ? 1 : 0));

  state._timeout = setTimeout(() => {
    state.loading = true;
    nextMessage();
  }, delay);
}
function nextMessage() {
  let messageIndex = state.messageIndex + 1;
  if (messageIndex >= state.messages.length) {
    messageIndex = 0;
  }
  state.messageIndex = messageIndex;

  if (state._timeout) {
    clearTimeout(state._timeout);
    state._timeout = null;
  }

  state._timeout = setTimeout(() => {
    state.loading = true;
    nextMessage();
  }, Math.max(Math.random() * 3000, 1000));
}

onMounted(() => {
  startToShowRandomMessages({ delay: 500 });
});

onBeforeUnmount(() => {
  if (state._timeout) {
    clearTimeout(state._timeout);
    state._timeout = null;
  }
});
</script>

<style lang="scss" scoped>
.page-content {
  @include column-flexbox(start);
  padding-top: 55%;

  .logo {
    position: relative;
    width: 70%;
    padding-top: 25%;

    img {
      position: absolute;
      top: 0;
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }

  .slogan {
    margin-top: unit(16);
    width: 100%;

    p {
      text-align: center;
      font-size: unit(18);
      font-weight: 600;
    }
  }

  .loader {
    position: absolute;
    left: 0;
    bottom: unit(48);
    margin-top: unit(160);
    width: 100%;

    p {
      margin-top: unit(24);
      text-align: center;
      color: #999;
      font-weight: 400;
      font-size: unit(14);
    }
  }
}
</style>
