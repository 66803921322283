import NotionRenderer from './lib/components/index.vue';
import './lib/scss/styles.css';
export { default as NotionBookmark } from './lib/components/blocks/bookmark.vue';
export { default as NotionCallout } from './lib/components/blocks/callout.vue';
export { default as NotionCode } from './lib/components/blocks/code.vue';
export { default as NotionColumn } from './lib/components/blocks/column.vue';
export { default as NotionDecorator } from './lib/components/blocks/decorator.vue';
export { default as NotionEquation } from './lib/components/blocks/equation.vue';
export { default as NotionHeader } from './lib/components/blocks/header.vue';
export { default as NotionList } from './lib/components/blocks/list.vue';
export { default as NotionPage } from './lib/components/blocks/page.vue';
export { default as NotionQuote } from './lib/components/blocks/quote.vue';
export { default as NotionTableRow } from './lib/components/blocks/table-row.vue';
export { default as NotionTable } from './lib/components/blocks/table.vue';
export { default as NotionText } from './lib/components/blocks/text.vue';
export { default as NotionTodo } from './lib/components/blocks/todo.vue';
export { default as NotionToggle } from './lib/components/blocks/toggle.vue';
export { default as NotionAsset } from './lib/components/helpers/asset.vue';
export { default as NotionColumnSpacer } from './lib/components/helpers/column-spacer.vue';
export { default as NotionFigure } from './lib/components/helpers/figure.vue';
export { default as NotionFragment } from './lib/components/helpers/fragment.vue';
export { default as NotionImage } from './lib/components/helpers/image.vue';
export { default as NotionNestedList } from './lib/components/helpers/nested-list.vue';
export { default as NotionPageHeader } from './lib/components/helpers/page-header.vue';
export { default as NotionPageIcon } from './lib/components/helpers/page-icon.vue';
export { default as NotionTextRenderer } from './lib/components/helpers/text-renderer.vue';

export { NotionRenderer };

const plugin = {
  install: (app, options = {}) => {
    app.component('NotionRenderer', NotionRenderer);
  },
};

export default plugin;
