export const getMapType = function (type: MapType | string) {
  if (typeof type === 'string') {
    switch (type.toLowerCase()) {
      case 'tmap':
        return MapType.TMAP;
      case 'naver':
        return MapType.NAVER;
      case 'google':
        return MapType.GOOGLE;
    }
    return MapType.UNKNOWN;
  }

  return type;
};

export const getMapConfig = function (type: string) {
  switch (type.toLowerCase()) {
    case 'tmap':
      return MapType.TMAP;
    case 'naver':
      return MapType.NAVER;
    case 'google':
      return MapType.GOOGLE;
  }
  return MapType.UNKNOWN;
};

export interface MapOptions {
  [propName: string]: any;
}

export interface MapMarkerOptions {
  id: any;
  latitude: number;
  longitude: number;
  zIndex?: number;
  iconUrl?: string;
  iconContent?: string;
  [propName: string]: any;
}

export interface MapInterface {
  map: any;
  initiated: boolean;
  loaded: boolean;
  load: Function;
  loadView: Function;
  destroy: Function;
  addMapEventListener: Function;
  removeMapEventListener: Function;
  addMarkerEventListener: Function;
  removeMarkerEventListener: Function;
  createMarker: Function;
  updateMarker: Function;
  removeMarker: Function;
  triggerEvent: Function;
  clearMarkers: Function;
  updateMarkers: Function;
  isInBounds: Function;
  getZoom: Function;
  setZoom: Function;
  panTo: Function;
  getCenter: Function;
  getRadius: Function;
  setSize: Function;
  resize: Function;
  getDistanceBetween: Function;
  setCenter: Function;
  convertTM128ToLatLng?: Function;
}

export enum MapType {
  'GOOGLE' = 'google',
  'MAPBOX' = 'mapbox',
  'NAVER' = 'naver',
  'TMAP' = 'tmap',
  'UNKNOWN' = '',
}
