/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { TermsAgreeListDto, TermsAgreeUpdateDto } from './data-contracts';
import { ContentType, HttpClient, RequestParams } from './http-client';

export class TermsAgreeApi<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags terms-agree-api
   * @name TermsAgreeControllerUserAgreeUpdate
   * @request PUT:/v1/terms/agree/{id}
   * @secure
   */
  termsAgreeControllerUserAgreeUpdate = (id: number, data: TermsAgreeUpdateDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/terms/agree/${id}`,
      method: 'PUT',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
  /**
   * No description
   *
   * @tags terms-agree-api
   * @name TermsAgreeControllerUserAgreeList
   * @request GET:/v1/terms/agree
   * @secure
   */
  termsAgreeControllerUserAgreeList = (params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/terms/agree`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags terms-agree-api
   * @name TermsAgreeControllerCheck
   * @request GET:/v1/terms/agree/check
   * @secure
   */
  termsAgreeControllerCheck = (params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/terms/agree/check`,
      method: 'GET',
      secure: true,
      ...params,
    });
  /**
   * No description
   *
   * @tags terms-agree-api
   * @name TermsAgreeControllerTermsAgreeList
   * @request POST:/v1/terms/agree/list
   * @secure
   */
  termsAgreeControllerTermsAgreeList = (data: TermsAgreeListDto, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/v1/terms/agree/list`,
      method: 'POST',
      body: data,
      secure: true,
      type: ContentType.Json,
      ...params,
    });
}
