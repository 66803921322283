import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_block_renderer = _resolveComponent("block-renderer")!

  return ($data.blockMap)
    ? (_openBlock(), _createBlock(_component_block_renderer, {
        key: 0,
        "block-map": $data.blockMap,
        "full-page": $props.fullPage,
        action: $options.onAction
      }, null, 8, ["block-map", "full-page", "action"]))
    : _createCommentVNode("", true)
}