import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "notion-list notion-list-disc"
}
const _hoisted_2 = {
  key: 1,
  class: "notion-list notion-list-numbered"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.type === 'bulleted_list')
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default")
      ]))
    : (_openBlock(), _createElementBlock("ol", _hoisted_2, [
        _renderSlot(_ctx.$slots, "default")
      ]))
}