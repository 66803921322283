export default [
  {
    path: '/my',
    component: () => import('@/pages/mypage/PageMypage.vue'),
    name: 'Mypage',
    meta: { title: '마이페이지' },
  },
  {
    path: '/my/onboarding',
    component: () => import('@/pages/mypage/PageOnboarding.vue'),
    name: 'Onboarding',
    meta: { title: '유후 시작 가이드' },
  },
  {
    path: '/my/passport/phone',
    component: () => import('@/pages/mypage/passport/PagePassportByPhone.vue'),
    name: 'PassportByPhone',
    meta: { title: '로그인/회원가입' },
  },
  {
    path: '/my/profile-update',
    component: () => import('@/pages/mypage/PageProfileUpdate.vue'),
    name: 'ProfileUpdate',
    meta: { title: '프로필 설정', auth: true },
  },
  {
    path: '/my/spaces',
    component: () => import('@/pages/mypage/PageMySpaces.vue'),
    name: 'MySpaces',
    meta: { title: '등록한 공간', auth: true },
  },
  {
    path: '/my/spaces/:id',
    component: () => import('@/pages/mypage/PageManageSpace.vue'),
    name: 'MySpaceManage',
    meta: { title: '공간 사용 관리', auth: true },
  },
  {
    path: '/my/book/from',
    component: () => import('@/pages/mypage/book/PageBookFrom.vue'),
    name: 'MyBookFrom',
    meta: { title: '신청한 공간', auth: true },
  },
  {
    path: '/my/book/to/:id',
    component: () => import('@/pages/mypage/book/PageBookDetailTo.vue'),
    name: 'MyBookDetailTo',
    meta: { title: '받은 신청 상세', auth: true },
  },
  {
    path: '/my/book/to/:id/reject',
    component: () => import('@/pages/mypage/book/PageBookDetailToRejectReason.vue'),
    name: 'MyBookDetailToRejectReason',
    meta: { title: '거절하기', auth: true },
  },
  {
    path: '/my/book/from/:id',
    component: () => import('@/pages/mypage/book/PageBookDetailFrom.vue'),
    name: 'MyBookDetailFrom',
    meta: { title: '보낸 신청 상세', auth: true },
  },
  {
    path: '/my/book/from/:id/cancel',
    component: () => import('@/pages/mypage/book/PageBookDetailFromCancelReason.vue'),
    name: 'MyBookDetailFromCancelReason',
    meta: { title: '공간 사용 신청 취소하기', auth: true },
  },
  {
    path: '/my/book/from/:id/refund',
    component: () => import('@/pages/mypage/book/PageBookDetailFromRefundReason.vue'),
    name: 'MyBookDetailFromRefundReason',
    meta: { title: '환불 요청하기', auth: true },
  },
  {
    path: '/my/book/from/:id/payment',
    component: () => import('@/pages/mypage/book/PageBookPayment.vue'),
    name: 'MyBookFromPayment',
    meta: { title: '신청 결제 정보', auth: true },
  },
  {
    path: '/my/proposal',
    component: () => import('@/pages/mypage/PageProposal.vue'),
    name: 'MyProposal',
    meta: { title: '제안 공간', auth: true },
  },
  {
    path: '/my/space-request',
    component: () => import('@/pages/mypage/PageMySpaceRequest.vue'),
    name: 'MySpaceRequest',
    meta: { title: '마이 공간 구하기', auth: true },
  },
  {
    path: '/my/app-setting',
    component: () => import('@/pages/mypage/PageAppSetting.vue'),
    name: 'AppSetting',
    meta: { title: '앱 설정' },
  },
  {
    path: '/my/favorite',
    component: () => import('@/pages/mypage/PageFavorite.vue'),
    name: 'Favorite',
    meta: { title: '관심', auth: true },
  },
  {
    path: '/my/policy/agrees',
    component: () => import('@/pages/mypage/policy/PageAgreeToPolicy.vue'),
    name: 'AgreeToPolicy',
  },
  {
    path: '/my/policy/agrees/marketing',
    component: () => import('@/pages/mypage/policy/PageAgreeToMarketing.vue'),
    name: 'AgreeToMarketing',
  },
  {
    path: '/my/policy/:path_key',
    component: () => import('@/pages/mypage/policy/PagePolicy.vue'),
    name: 'Policy',
  },
  {
    path: '/my/support/faq',
    component: () => import('@/pages/mypage/support/PageFaq.vue'),
    name: 'FAQ',
  },
  {
    path: '/my/support/:id',
    component: () => import('@/pages/mypage/support/PageGuide.vue'),
    name: 'Guide',
  },
  {
    path: '/my/payment',
    component: () => import('@/pages/mypage/payment/PagePaymentList.vue'),
    name: 'PagePaymentList',
    meta: { title: '결제수단', auth: true },
  },
  {
    path: '/my/payment/add',
    component: () => import('@/pages/mypage/payment/PageAddPayment.vue'),
    name: 'AddPayment',
    meta: { title: '결제수단 추가', auth: true },
  },
  {
    path: '/my/payment/add/eximbay',
    component: () => import('@/pages/mypage/payment/PageAddPaymentEximbay.vue'),
    name: 'AddPaymentEximbay',
    meta: { title: 'AddPaymentEximbay' },
    props: true,
  },
  {
    path: '/my/payment/add/nicepay',
    component: () => import('@/pages/mypage/payment/PageAddPaymentNicepay.vue'),
    name: 'AddPaymentNicepay',
    meta: { title: 'AddPaymentNicepay' },
    props: true,
  },

  // for KakaoPay
  {
    path: '/my/payment/add/kakaopay',
    component: () => import('@/pages/mypage/payment/PageAddPaymentKakaopay.vue'),
    name: 'AddPaymentKakaopay',
    meta: { title: 'AddPaymentKakaopay' },
    props: true,
  },
  {
    path: '/my/payment/add/kakaopay/popup/:token/:state',
    component: () => import('@/pages/mypage/payment/PageAddPaymentKakaopayPopup.vue'),
    name: 'AddPaymentKakaopayPopup',
    meta: { title: 'AddPaymentKakaopayPopup' },
    props: true,
  },
  {
    path: '/my/payment/add/easy-payment-regist',
    component: () => import('@/pages/mypage/payment/PageAddPaymentEasypayment.vue'),
    name: 'AddPaymentEasypayment',
    meta: { title: 'AddPaymentEasypayment' },
    props: true,
  },
  {
    path: '/my/settlement',
    component: () => import('@/pages/mypage/settlement/PageSettlement.vue'),
    name: 'Settlement',
    meta: { title: '수익관리', auth: true },
  },
  {
    path: '/my/settlement/:space_id',
    component: () => import('@/pages/mypage/settlement/PageSpaceSettlement.vue'),
    name: 'SpaceSettlement',
    meta: { title: '공간별 수익 관리', auth: true },
  },
  {
    path: '/my/settlement/:space_id/:settlement_id',
    component: () => import('@/pages/mypage/settlement/PageSettlementDetail.vue'),
    name: 'SettlementDetail',
    meta: { title: '정산 상세 정보', auth: true },
  },
  {
    path: '/my/settlement/bank-account',
    component: () => import('@/pages/mypage/settlement/PageSettlementBankAccount.vue'),
    name: 'SettlementBankAccount',
    meta: { title: '정산 계좌 등록' },
    props: true,
  },
  {
    path: '/my/settlement/tax-invoice',
    component: () => import('@/pages/mypage/settlement/PageSettlementTaxInvoice.vue'),
    name: 'SettlementTaxInvoice',
    meta: { title: '계산서 발행 정보 등록' },
    props: true,
  },
];
