<template>
  <div>
    <input type="checkbox" :value="title" :checked="properties.checked" />
    <label>
      <NotionTextRenderer :text="title" v-bind="pass" />
    </label>
  </div>
</template>

<script lang="ts">
// TODO: add notion styling
// TODO: add strikethrough

import { Blockable } from '../../composable/blockable';
import NotionTextRenderer from '../helpers/text-renderer.vue';

export default {
  name: 'NotionTodo',
  components: {
    NotionTextRenderer,
  },
  extends: Blockable,
};
</script>
