import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PrismComponent = _resolveComponent("PrismComponent")!

  return (_ctx.prism && $options.supported)
    ? (_openBlock(), _createElementBlock("pre", {
        key: 0,
        class: _normalizeClass(['notion-code', $options.langClass])
      }, [
        _createVNode(_component_PrismComponent, { language: $options.lang }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.properties.title[0][0]), 1)
          ]),
          _: 1
        }, 8, ["language"])
      ], 2))
    : (_openBlock(), _createElementBlock("pre", {
        key: 1,
        class: _normalizeClass(['notion-code', $options.langClass])
      }, [
        _createElementVNode("code", {
          class: _normalizeClass($options.langClass)
        }, _toDisplayString(_ctx.properties.title[0][0]), 3)
      ], 2))
}